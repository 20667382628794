import { MdOutlineDashboard } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import { VscReferences } from "react-icons/vsc";
import { FaNetworkWired } from "react-icons/fa6";
import { IoStatsChartSharp } from "react-icons/io5";
import { AiOutlineLogout } from "react-icons/ai";

export const sidebarLink = [
  {
    name: "Dashboard",
    path: "/portal/dashboard",
    icon: <MdOutlineDashboard size={20} />,
  },
  {
    name: "User Profile",
    path: "/portal/profile",
    icon: <FaRegUser size={20} />,
  },
  {
    name: "Referral Management",
    path: "/referral-management",
    icon: <VscReferences size={20} />,
  },
  {
    name: "Network",
    path: "/network",
    icon: <FaNetworkWired size={20} />,
  },
  {
    name: "Statics",
    path: "/statistics",
    icon: <IoStatsChartSharp size={20} />,
  },
  {
    name: "Logout",
    path: "/logout",
    icon: <AiOutlineLogout size={20} />,
  },
];

export const headerNav = [
  {
    path: "/",
    title: "Home",
  },
  {
    path: "/who-we-are",
    title: "Who We Are",
  },
  {
    path: "/how-it-works",
    title: "How It Works",
  },
  {
    path: "/contact",
    title: "Contact",
  },
];

export const footerNav = [
  {
    path: "/",
    title: "Home",
  },
  {
    path: "/who-we-are",
    title: "Who We Are",
  },
  {
    path: "/how-it-works",
    title: "How It Works",
  },
  {
    path: "/contact",
    title: "Contact",
  },
  {
    path: "/register",
    title: "Become A Member",
  },
  {
    path: "/",
    title: "Sitemap",
  },
];

export const accountLink = [
  {
    path: "/portal/profile",
    title: "Profile",
    delegate: false
  },
  {
    path: "/portal/dashboard",
    title: "Dashboard",
    delegate: true
  },
  {
    path: "/logout",
    title: "Logout",
    delegate: true
  },
];
