import { Link } from "react-router-dom";

const ErrorPageRoot = () => {
  return (
    <section className="formpart bg-gray-100">
      <div className="flex flex-col  items-center justify-center max-h-screen">
        <h1 className="text-8xl font-bold mb-4 animate-bounce">404</h1>
        <div className="text-lg mb-8">Page Not Found</div>
        {/* <img
          src="/images/404.svg"
          alt="404 Error"
          className="max-w-xs animate-pulse"
        /> */}
        <Link to="/" className="text-blue-500 hover:underline mt-8">
          Go back to Home
        </Link>
      </div>
    </section>
  );
};

export default ErrorPageRoot;
