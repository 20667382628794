import { Form, Formik } from "formik";
import { useCallback, useEffect, useRef, useState } from "react";
import CustomInput from "../../component/form/customInput";
import CustomButton, { ResetButton } from "../../component/form/customButton";
import { Api } from "../../api/api";
import Loader from "../../component/layout/loader";
import { useNavigate, useLocation } from "react-router-dom";
import { defaultCountryCode } from "helper/constant";
import CustomAutocomplete from "component/form/customAutocomplete";
import { useContext } from "react";
import { FieldContext } from "@contexts/fieldContext";
import SweetAlert from "react-bootstrap-sweetalert";
import CitySelect from "component/form/citySelect";

const Register = () => {
  // const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedState, setSelectedState] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [checkedItems, setCheckedItems] = useState(new Map());
  const [errorMsg, setErrorMessage] = useState("");
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [selectedFacility, setSelectedFacility] = useState();
  const [selectError, setSelectError] = useState(null);
  const [selectStateError, setSelectStateError] = useState(null);
  const [selectCityError, setSelectCityError] = useState(null);
  const [other, setOther] = useState("");
  const [showOtherInput, setShowOtherInput] = useState(false);
  const { facilityList, stateList, insuranceTypeList } =
    useContext(FieldContext);
  const location = new URLSearchParams(window.location.search);
  const queryName = location.get("n");
  const queryEmail = location.get("e");
  const navigate = useNavigate();
  const [formValue, setFormValue] = useState({
    facilityName: "",
    facilityType: "",
    contactName: queryName !== "" ? queryName : "",
    phoneNumber: "",
    email: queryEmail !== "" ? queryEmail : "",
    website: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    insuranceType: "",
    receiverName: "",
    cellNumber: "",
    referralEmail: "",
    password: "",
    confirmPassword: "",
    other: "",
  });
  const formikRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  // const handleInputChange = (value) => {
  // setSearchCity(value);
  // console.log("bbbb", value);
  // setLoading(true);
  // const newOptions = cityList.filter((city) =>
  //   city.name.toLowerCase().includes(value.toLowerCase())
  // );
  // setFilteredOptions(newOptions);
  // setLoading(false);
  // };

  useEffect(() => {
    insuranceTypeList.map((item) =>
      setCheckedItems(new Map(checkedItems.set(item.id, true)))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insuranceTypeList]);

  const handleResetForm = () => {
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
  };

  const handleChange = (event, itemId) => {
    const itemChecked = event.target.checked;
    if (itemId !== "other") {
      setCheckedItems(new Map(checkedItems.set(itemId, itemChecked)));
    }

    if (itemId === "other") {
      setShowOtherInput(itemChecked);
      if (!itemChecked) {
        setOther("");
      }
    }
  };

  const handleCloseError = () => {
    setShowErrorDialog(false);
  };

  const handleSelect = (selected, fieldName) => {
    if (fieldName === "facilityType") {
      setSelectedFacility(selected);
      setSelectError(selected === null ? "Facility Type is required" : "");
    } else if (fieldName === "State") {
      setSelectedState(selected);
      setSelectStateError(selected === null ? "State is required" : "");
      setSelectedCity({});
    } else if (fieldName === "City") {
      setSelectedCity(selected);
      setSelectCityError(selected === null ? "City is required" : "");
    }
  };
  const PersonalInfo = ({ errors, touched, setFieldValue }) => (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 text-left">
        <CustomInput label="Facility Name" name="facilityName" />
        <CustomAutocomplete
          label={"Facility Type"}
          id={"facilityType"}
          name={"facilityType"}
          placeholder={"Facility Type"}
          options={facilityList}
          defaultValue={selectedFacility && selectedFacility}
          onChange={(e) => {
            setSelectedFacility(e);
            errors.facilityType = e === null ? "Facility Type is required" : "";
            setFieldValue("facilityType", e);
          }}
          selectError={errors.facilityType}
          errors={errors}
          touched={touched}
        />

        <CustomInput type="text" label="Contact Name" name="contactName" />
        <CustomInput
          type="text"
          label="Phone Number"
          name="phoneNumber"
          placeholder="+1 555 555 5555"
          onKeyPress={(e) => {
            const allowedKeys = /[0-9\b\r\t\+]/;
            if (!allowedKeys.test(e.key)) {
              e.preventDefault();
            }
          }}
        />
        {queryEmail !== "" && queryEmail != null ? (
          <CustomInput
            label="Email Address"
            type="email"
            name="email"
            readonly={true}
          />
        ) : (
          <CustomInput label="Email Address" type="email" name="email" />
        )}

        <CustomInput
          label="Website Address"
          name="website"
          placeholder="google.com"
        />
        <div className="md:col-span-2 ">
          <CustomInput type={"textarea"} label="Address" name="address" />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 text-left">
        <CustomAutocomplete
          label={"State"}
          id={"state"}
          name={"state"}
          placeholder={"State"}
          options={stateList?.map((state) => ({
            value: state.id,
            label: state.name,
          }))}
          // inputValue={searchState}
          defaultValue={selectedState && selectedState}
          onChange={(e) => {
            setSelectedState(e);
            setSelectedCity("");
            setFieldValue("state", e); // Ensure that the selected state is being set in Formik's state
            errors.state = e === null ? "State is required" : "";
          }}
          // onInputChange={handleSearchState}
          selectError={errors.state}
          errors={errors}
          touched={touched}
        />

        <CitySelect
          label={"City"}
          id={"city"}
          name={"city"}
          placeholder={"City"}
          selectedState={selectedState}
          defaultValue={selectedCity && selectedCity}
          onChange={(e) => {
            setSelectedCity(e);
            errors.city = e === null ? "City is required" : "";
            setFieldValue("city", e);
          }}
          selectError={errors.city}
          errors={errors}
          touched={touched}
        />
        <CustomInput type="text" label="Zip Code" name="zip" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 text-left">
        <div className="md:col-span-2 mx-3 mb-3">
          <label
            htmlFor="whoReceivesReferrals"
            className="block text-base font-bold text-gray-700"
          >
            Type Of Insurances Accepted
          </label>
          <div className="grid lg:grid-cols-4 md:grid-cols-3 mt-3">
            {insuranceTypeList.map((item, index) => {
              return (
                <div className="flex mb-2 items-center me-4" key={index}>
                  <input
                    checked={checkedItems.get(item.id) || false}
                    onChange={(e) => handleChange(e, item.id)}
                    id={`inline-checkbox-${index}`}
                    name="insurance"
                    type="checkbox"
                    value={item.name}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor={`inline-checkbox-${index}`}
                    className="ms-2 text-sm font-medium text-black-900"
                  >
                    {item.name}
                  </label>
                </div>
              );
            })}
            <div className="flex mb-2 items-center me-4">
              <input
                checked={showOtherInput}
                onChange={(e) => handleChange(e, `other`)}
                id={`inline-checkbox-other`}
                name="insurance-other"
                type="checkbox"
                value={showOtherInput}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
              />
              <label
                htmlFor={`inline-checkbox-other`}
                className="ms-2 text-sm font-medium text-black-900"
              >
                Other
              </label>
            </div>
          </div>
          {showOtherInput && (
            <div className="mb-3 mx-3">
              <CustomInput
                type="text"
                label="Other Insurance Type"
                name={"other"}
                id={"other"}
              />
            </div>
          )}
        </div>
        <CustomInput
          label="Password"
          name="password"
          type="password"
          autoComplete="off"
        />
        <CustomInput
          label="Confirm Password"
          name="confirmPassword"
          type="password"
          autoComplete="off"
        />
      </div>
    </>
  );

  const validate = (values) => {
    const errors = {};

    if (!values.facilityName) {
      errors.facilityName = "Facility Name is required";
      // setSelectError("Facility Type is required");
    }
    if (!values.facilityType) {
      errors.facilityType = "Facility Type is required";
      // setSelectError("Facility Type is required");
    }

    if (!values.contactName) {
      errors.contactName = "Contact Name is required";
    }

    if (!values.phoneNumber) {
      errors.phoneNumber = "Phone Number is required";
    } else if (!/^\+1[0-9]{10}$/.test(values.phoneNumber)) {
      errors.phoneNumber = "Invalid phone number";
    }

    if (!values.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = "Invalid email address";
    }

    if (!values.website) {
      errors.website = "Website is required";
    } else if (!/^(\S+\.\S+)$/.test(values.website)) {
      errors.website = "Invalid website URL";
    }

    if (!values.address) {
      errors.address = "Address is required";
    }

    if (!values.state) {
      errors.state = "State is required";
      // setSelectStateError("State is required");
    }

    if (!values.city) {
      errors.city = "City is required";
      // setSelectCityError("City is required");
    }

    // if (!values.country) {
    //   errors.country = "Country is required";
    // }

    if (!values.zip) {
      errors.zip = "Zip is required";
    } else if (!/^\d{5,}$/.test(values.zip)) {
      errors.zip = "Invalid zip code";
    }

    // if (!values.insuranceType) {
    //   errors.insuranceType = "Insurance Type is required";
    // }
    if (!values.password) {
      errors.password = "Password is required";
    } else if (values.password.length < 6) {
      errors.password = "Password must be at least 6 characters";
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = "Confirm Password is required";
    } else if (values.confirmPassword !== values.password) {
      errors.confirmPassword = "Passwords do not match";
    }
    if (checkedItems.get("other") && !values.other) {
      errors.other = "Other Insurance Type is required";
    }

    // if (!values.receiverName) {
    //   errors.receiverName = "Receiver Name is required";
    // }

    // if (!values.cellNumber) {
    //   errors.cellNumber = "Cell Number is required";
    // } else if (!/^[0-9]{10}$/.test(values.cellNumber)) {
    //   errors.cellNumber = "Invalid cell number";
    // }

    // if (!values.referralEmail) {
    //   errors.referralEmail = "Email is required";
    // } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    //   errors.referralEmail = "Invalid email address";
    // }
    return errors;
  };

  const onSubmit = (values, { setSubmitting }) => {
    if (!selectedCity || !selectedState || !selectedFacility) {
      if (!selectedCity) {
        setSelectStateError("State is required");
      }
      if (!selectedState) {
        setSelectCityError("City is required");
      }
      if (!selectedFacility) {
        setSelectError("Facility Type is required");
      }
      return;
    }
    const type_of_insurances_accepted = [];
    checkedItems.forEach((item, key) => {
      if (item) {
        type_of_insurances_accepted.push(key);
      }
    });

    // if (showOtherInput && values.other) {
    //   type_of_insurances_accepted.push(values.other);
    // }

    setLoading(false);
    setFormValue(values);
    setSubmitLoading(true);
    const formData = new FormData();
    formData.append("facility_by", selectedFacility?.value);
    formData.append("facility_name", values.facilityName);
    formData.append("email", values.email);
    formData.append("contact_name", values.contactName);
    formData.append("contact_no", values.phoneNumber);
    formData.append("website", values.website);
    formData.append("address", values.address);
    formData.append("city", selectedCity?.value);
    formData.append("state", selectedState?.value);
    formData.append("country", defaultCountryCode);
    formData.append("postal_code", values.zip);
    formData.append(
      "type_of_insurances_accepted[]",
      type_of_insurances_accepted
    );
    formData.append("password", values.password);
    formData.append("add_other_insurances", values.other);
    // formData.append("who_receives_referrals", values.receiverName);
    // formData.append("referrals_contact_no", values.cellNumber);
    // formData.append("referrals_email", values.referralEmail);

    Api.register(formData)
      .then((res) => {
        if (res?.status === 200) {
          // successToast(res?.data?.message, 5);
          setLoading(false);
          setSubmitting(false);
          handleResetForm();
          setFormValue({});
          setShowDialog(true);
          setSubmitLoading(false);
        }
      })
      .catch((res) => {
        setErrorMessage(res.response?.data?.message);
        // errorToast(res.response?.data?.message);
        setLoading(false);
        setShowErrorDialog(true);
        // handleResetForm();
        setSubmitting(false);
        setSubmitLoading(false);
      });
  };

  const handleClose = () => {
    setShowDialog(false);
    return navigate("/login");
  };
  return (
    <>
      <section>
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto mt-5 mb-5 lg:py-0">
          <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-5xl xl:p-0 dark:bg-gray-800">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Register
              </h1>
              <p className="text-black-500 py-0 mt-2">
                Please fill out the form below to register
              </p>
              {loading ? (
                <Loader />
              ) : (
                <Formik
                  initialValues={formValue}
                  validate={validate}
                  onSubmit={onSubmit}
                  innerRef={formikRef}
                >
                  {({ isSubmitting, errors, touched, setFieldValue }) => (
                    <Form>
                      <div className="mt-8">
                        <PersonalInfo
                          errors={errors}
                          touched={touched}
                          setFieldValue={setFieldValue}
                        />
                      </div>

                      {Object.keys(errors).length > 0 && (
                        <div className="text-red-500 mt-2 mb-2 flex justify-center">
                          Please complete missing fields above.
                        </div>
                      )}
                      <div className="mt-2 flex justify-center">
                        {
                          submitLoading ? (
                            <div className="flex justify-center items-center">
                              <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-gray-900"></div>
                            </div>
                          ) :
                            (
                              <CustomButton type="submit">
                                {`Sign Up`}
                              </CustomButton>
                            )
                        }

                        <ResetButton onClick={handleResetForm}>
                          Reset
                        </ResetButton>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </div>
          <SweetAlert
            success
            show={showDialog}
            title="Thank you for registering! An email has been sent to your provided
            email address for verification. Please verify your email before
            logging in to access all features."
            onConfirm={handleClose}
            confirmBtnCssClass="bg-primary-500 rounded-lg text-xl text-white hover:bg-blue-600"
          />
          <SweetAlert
            error
            show={showErrorDialog}
            title={errorMsg || " "}
            onConfirm={handleCloseError}
            confirmBtnCssClass="bg-primary-500 rounded-lg text-xl text-white hover:bg-blue-600"
          />
        </div>
      </section >
    </>
  );
};
export default Register;
