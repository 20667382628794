import React, { useState, useRef, useEffect } from 'react';
import '../../assets/css/signature.css';
import Logo from "../../assets/images/healthcare-referral-logo-small.png";
import { Image } from "react-bootstrap";
import SignatureCanvas from 'react-signature-canvas';
import toast from "react-hot-toast";
import { useParams, useNavigate } from "react-router-dom";
import { Api } from 'api/api';
import WidgetsLoader from "@components/WidgetsLoader";
import SweetAlert from 'react-bootstrap-sweetalert';
import moment from 'moment';
import Visibility from "@mui/icons-material/Visibility";
import styled from "styled-components/macro";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { styled as styledStyle } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styledStyle(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const Signature = () => {
    const [openModel, setOpenModal] = useState(false);
    const sigCanvas = useRef();
    const [penColor, setPenColor] = useState("black");
    const colors = ["black", "green", "red"];
    const [imageURL, setImageURL] = useState(null);
    const token = useParams().token;
    const [loading, setLoading] = useState(true);
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [signDetails, setSignDetails] = useState(null);
    const [isDownload, setIsDownload] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [isHide, setIsHide] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState("1");
    const [dischargeInstructionsList, setDischargeInstructionsList] = useState(
        []
    );
    const [medicalRecordsList, setMedicalRecordsList] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        getDetails(token);
    }, [token]);

    const getDetails = (token) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("esing_token", token);
        Api.eSignFetch(formData)
            .then((res) => {
                if (res.data) {
                    setSignDetails(res.data);
                    setDischargeInstructionsList(res.data.upload_discharge_instructions_list);
                    setMedicalRecordsList(res.data.upload_medical_records_list)
                    setLoading(false);
                }
                else {
                    setShowErrorDialog(true);
                    setLoading(false);
                }
            })
            .catch((error) => {
                setShowErrorDialog(true);
                setLoading(false);
                // toast.error(error.errorme)
            });
    }
    const create = () => {
        if (!sigCanvas.current.isEmpty()) {
            // setLoading(true);
            setIsHide(true);
            const URL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
            const formData = new FormData();
            formData.append("esing_token", token);
            formData.append("esing_image", URL);
            Api.eSignStore(formData)
                .then((res) => {
                    if (res.data.status === true) {
                        setImageURL(URL);
                        setOpenModal(false);
                        setPdfUrl(res.data.data?.esign_pdf)
                        // setLoading(false);
                        setIsHide(false);
                    }
                })
                .catch((error) => {
                    toast.error('The selected esing token is invalid.');
                });
        }
        else {
            toast.error('please add signature');
        }
    }
    const handleCloseError = () => {
        setShowErrorDialog(false);
        return navigate("/");
    };

    const handleClose = () => {
        setOpen(false);
    };

    const DocumentStyle = styled.div`
        display: flex;
        flex-direction: row;
        gap: 5px;
        padding: 2px 10px;
    `;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        // <div dangerouslySetInnerHTML={{ __html: htmlString }} />
        <div>
            <>
                {
                    loading ? (
                        <WidgetsLoader />
                    ) :
                        (
                            <>
                                {
                                    !showErrorDialog && (
                                        <>
                                            <div className='grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-1 gap-4 mb-3'>
                                                <div ></div>
                                                <div className='ml-1 mr-1 sm:ml-5 sm:mr-5 md:col-start-2 md:col-span-2 lg:col-end-4 lg:col-start-2 lg:col-span-2 lg:col-end-4 border-2 border-black mt-3'>
                                                    <div className="grid grid-cols-1 gap-4 mb-3">
                                                        <center> <Image src={Logo} style={{ width: "230px", height: "100px", margin: "10px 0px" }} /></center>
                                                    </div>
                                                    <hr />
                                                    <div className="grid grid-cols-1 gap-4 mb-3">
                                                        <h3 className="signature-title">Patient Information</h3>
                                                    </div>
                                                    <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-4 mb-3">
                                                        <div>
                                                            <b>Referral Patient Name :- </b>
                                                            <span>{signDetails?.first_name + ' ' + signDetails?.last_name}</span>
                                                        </div>
                                                        <div>
                                                            <b>Email :- </b>
                                                            <span>{signDetails?.email}</span>
                                                        </div>
                                                        <div>
                                                            <b>Phone :- </b>
                                                            <span>{signDetails?.phone_number}</span>
                                                        </div>
                                                        <div>
                                                            <b>Cell :- </b>
                                                            <span>{signDetails?.cell_number}</span>
                                                        </div>
                                                        <div>
                                                            <b>Date of Birth :- </b>
                                                            <span>{moment(signDetails?.date_of_birth).format('MM-DD-YYYY')}</span>
                                                        </div>
                                                        <div>
                                                            <b>Preferred Language :- </b>
                                                            <span>{signDetails?.preferred_language}</span>
                                                        </div>
                                                        <div>
                                                            <b>Sex :- </b>
                                                            <span style={{ textTransform: 'capitalize' }}>{signDetails?.sex}</span>
                                                        </div>
                                                        <div>
                                                            <b>Patient Needs :- </b>
                                                            <span>{signDetails?.patient_needs}</span>
                                                        </div>
                                                        <div>
                                                            <b>Address :- </b>
                                                            <span>{signDetails?.address}</span>
                                                        </div>
                                                        <div>
                                                            <b>City :- </b>
                                                            <span>{signDetails?.city_name}</span>
                                                        </div>
                                                        <div>
                                                            <b>Zip Code :- </b>
                                                            <span>{signDetails?.postal_code}</span>
                                                        </div>
                                                        <div>
                                                            <b>State :- </b>
                                                            <span>{signDetails?.state_name}</span>
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-cols-1 gap-4 mb-3">
                                                        <h3 className="signature-title">Medical Insurance</h3>
                                                    </div>
                                                    <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-4 mb-3">
                                                        <div>
                                                            <b>Insurance Provider :- </b>
                                                            <span>{signDetails?.insurance_provider}</span>
                                                        </div>
                                                        <div>
                                                            <b>Medical Member Id Number :-</b>
                                                            <span>{signDetails?.medical_member_id_number}</span>
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-cols-1 gap-4 mb-3">
                                                        <h3 className="signature-title">Medical Facilities</h3>
                                                    </div>
                                                    <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-4 mb-3">
                                                        <div>
                                                            <b>Facility Type :- </b>
                                                            <span>{signDetails?.facility_type_name}</span>
                                                        </div>
                                                        <div>
                                                            <b>Actual Medical Facility :-</b>
                                                            <span>{signDetails?.actual_medical_facility}</span>
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-cols-1 gap-4 mb-3">
                                                        <h3 className="signature-title">Discharge Logistics</h3>
                                                    </div>
                                                    <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-4 mb-3">
                                                        <div>
                                                            <b>Patient Transportation :- </b>
                                                            <span style={{ textTransform: 'capitalize' }}>{signDetails?.patient_transportation}</span>
                                                        </div>
                                                        <div>
                                                            <>
                                                                {
                                                                    imageURL ? (
                                                                        <>
                                                                            <center><Image src={imageURL} alt="signature" className="signature" /></center>
                                                                        </>
                                                                    )
                                                                        :
                                                                        <center><button onClick={() => setOpenModal(true)} style={{ color: "#065697", textAlign: "center" }}>Signature</button></center>
                                                                }
                                                            </>
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-cols-1 gap-4" style={{ backgroundColor: "#065697", color: "#fff", padding: "10px" }}>
                                                        <center>
                                                            <p>© Copyright 2024 | <a className='sign-footer' style={{ color: "#fff", textDecoration: "underline !important" }} href="" >Him First Media
                                                                Group</a></p>
                                                        </center>
                                                    </div>
                                                </div>
                                                <div className='mt-5 ml-2'>
                                                    {
                                                        pdfUrl !== null && (
                                                            <a target={'_blank'} href={pdfUrl}
                                                                className="mt-4 px-4 py-2 bg-primary-500 rounded-lg text-xl text-white hover:bg-blue-600"
                                                            >Download</a>
                                                        )
                                                    }
                                                    <div className='mt-3'>
                                                        <a href="javascript:;"
                                                            onClick={() => {
                                                                setOpen(true);
                                                            }}
                                                            className="mt-4 px-4 py-2 bg-primary-500 rounded-lg text-xl text-white hover:bg-blue-600"
                                                        >View Documents</a>
                                                    </div>

                                                </div>
                                            </div>
                                            <>
                                                {openModel && (
                                                    <div className="fixed inset-0 overflow-y-auto flex justify-center items-center signature-dialog">
                                                        <div className="fixed sm:fixed inset-0 dialog-class"></div>
                                                        <div className="bg-white shadow-lg p-5 flex justify-center flex-col items-center z-50 sm:50 md:w-75 lg:w-50 rounded-3xl background-dialog">
                                                            <div className="sigPadContainer">
                                                                <SignatureCanvas penColor={penColor} canvasProps={{ className: "sigCanvas" }} ref={sigCanvas} />
                                                            </div>
                                                            <hr />
                                                            <div className='grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-1 gap-4'>

                                                                <button
                                                                    className="mt-4 px-4 me-2 py-2 bg-red-500 rounded-lg text-xl text-white hover:bg-red-600"
                                                                    onClick={() => sigCanvas.current.clear()}
                                                                >
                                                                    Clear
                                                                </button>
                                                                {!isHide && (
                                                                    <button
                                                                        className="md:mt-4 lg:mt-4 sm:mt-0 px-4 py-2 me-2 bg-green-500 rounded-lg text-xl text-white hover:bg-green-600"
                                                                        onClick={create}
                                                                    >
                                                                        Submit
                                                                    </button>
                                                                )}

                                                                <button
                                                                    className="md:mt-4 lg:mt-4 sm:mt-0 px-4 py-2 bg-primary-500 rounded-lg text-xl text-white hover:bg-blue-600"
                                                                    onClick={() => setOpenModal(false)}
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </div>

                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        </>
                                    )
                                }
                            </>
                        )
                }
            </>
            <SweetAlert
                error
                show={showErrorDialog}
                title="Token is expired."
                onConfirm={handleCloseError}
                confirmBtnCssClass="bg-primary-500 rounded-lg text-xl text-white hover:bg-blue-600"
            />

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Document List
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                <TabList
                                    allowScrollButtonsMobile
                                    variant="scrollable"
                                    onChange={handleChange}
                                    aria-label="lab API tabs example"
                                >
                                    <Tab label="Discharge Instructions" value="1" />
                                    <Tab label="Medical Records" value="2" />
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                {dischargeInstructionsList?.map((file, index) => {
                                    let titleArray = file.document_link.split('/');
                                    let title = titleArray[titleArray.length - 1];
                                    return (
                                        <div className="mt-3">
                                            <DocumentStyle key={index}>
                                                <p> {title} </p>
                                                <a
                                                    href={file.document_link}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    style={{ marginRight: "10px" }}
                                                >
                                                    <Visibility color="primary" sx={{ mb: "3px" }} />
                                                </a>
                                            </DocumentStyle>
                                        </div>
                                    )
                                })}
                            </TabPanel>
                            <TabPanel value="2">
                                {medicalRecordsList?.map((file, index) => {
                                    let titleArray = file.document_link.split('/');
                                    let title = titleArray[titleArray.length - 1];
                                    return (
                                        <div className="mt-3">
                                            <DocumentStyle key={index}>
                                                <p> {title} </p>
                                                <a
                                                    href={file.document_link}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    style={{ marginRight: "10px" }}
                                                >
                                                    <Visibility color="primary" sx={{ mb: "3px" }} />
                                                </a>
                                            </DocumentStyle>
                                        </div>
                                    )
                                })}
                            </TabPanel>
                        </TabContext>
                    </Box>
                </DialogContent>
            </BootstrapDialog>
        </div >
    )
}
export default Signature