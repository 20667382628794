import { Api } from "api/api";

const { createSlice } = require("@reduxjs/toolkit");

const utilSlice = createSlice({
  name: "util",
  initialState: {
    isLoading: false,
    facilityList: [],
  },
  reducers: {
    setFacilityType: (state, action) => {
      state.facilityList = action.payload;
    },
    getFacilityType: (state, action) => {
      //   if (state.facilityList.length === 0) {
      const fetchFacilityType = async () => {
        try {
          const res = await Api.getFacilityType();
          if (res?.status === 200) {
            state.facilityList = res.data.data?.map((itm) => ({
              label: itm,
              value: itm,
            }));
          }
        } catch (error) {
          console.error("Error fetching facility types:", error);
        } finally {
          state.isLoading = false;
        }
      };

      fetchFacilityType();
      state.isLoading = true;
    },
  },
  //   },
});

export const { setFacilityType, getFacilityType } = utilSlice.actions;
export default utilSlice.reducer;
