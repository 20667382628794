import { configureStore } from "@reduxjs/toolkit";
import LayoutsReducer from "./features/layout";
import TodosReducer from "./features/todos";
import CardsReducer from "./features/cards";
import MessengerReducer from "./features/messenger";
import UserReducer from "./userSlice";
import UtilReducer from "./utilSlice";
const store = configureStore({
  reducer: {
    layout: LayoutsReducer,
    todos: TodosReducer,
    cards: CardsReducer,
    messenger: MessengerReducer,
    user: UserReducer,
    util: UtilReducer,
  },
});

export default store;
