// utils
import { Suspense, useRef, useEffect } from "react";

// components
import Panel from "@layout/Panel";
import Sidebar from "@layout/Sidebar";
import { Route, Routes } from "react-router-dom";
import WidgetsLoader from "@components/WidgetsLoader";

// hooks
import ProtectedRoute from "frontend/routing/protectedRouting";
import ReferralProvider from "@contexts/referralContext";
import ErrorPageRoot from "frontend/pages/errorPage";

import { DynamicDelegateMenu } from "helper/helper";
import ReferAFacility from "@pages/ReferAFacility";
import Chats from "@pages/Chats";

const AppLayout = () => {
  const appRef = useRef(null);
  useEffect(() => {
    if (appRef.current) {
      appRef.current.scrollTo(0, 0);
    }
  }, []);

  const renderMenuItems = (menuItems) => {
    return menuItems?.map((item, index) => (
      <Route
        key={index}
        path={item.path}
        {...item}
        element={<ProtectedRoute> {item.element}</ProtectedRoute>}
      />
    ));
  };

  const renderDynamicMenu = () => {
    return renderMenuItems(DynamicDelegateMenu("route"));
  };

  return (
    <div className="app" ref={appRef}>
      {/* {isOverflow ? <ScrollProgress /> : null} */}
      <Sidebar />
      <div className="app_content">
        <ReferralProvider>
          <Panel />
          <Suspense fallback={<WidgetsLoader />}>
            <Routes>
              <Route path="/portal">
                {renderDynamicMenu()}
                {/* {user !== null &&
                  user?.current_subscription_plan_name !== null &&
                  todayDate < newDate ? (
                  <>
                    {
                      user?.role !== 'delegate_access' ? (
                        <>
                          {
                            dashboardRoute?.map(
                              (item, i) =>
                              (
                                <Route
                                  key={i}
                                  path={item.path}
                                  {...item}
                                  element={
                                    <ProtectedRoute> {item.element}</ProtectedRoute>
                                  }
                                />
                              )
                            )
                          }
                        </>
                      )
                        :
                        (
                          <>
                            {
                              dashboardRoute?.map(
                                (item, i) =>
                                  item?.hasDelegate && (
                                    <Route
                                      key={i}
                                      path={item.path}
                                      {...item}
                                      element={
                                        <DelegateRoute> {item.element}</DelegateRoute>
                                      }
                                    />
                                  )
                              )
                            }
                          </>
                        )
                    }
                  </>
                ) : (
                  <>
                    {dashboardRoute.map(
                      (item, i) =>
                        item.hasDelegate &&
                        !item.advance &&
                        !item.requireCheck && (
                          <Route
                            key={i}
                            path={item.path}
                            element={
                              <ProtectedRoute path={item.path}>
                                {item.element}
                              </ProtectedRoute>
                            }
                          />
                        )
                    )}
                  </>
                )} */}
                <Route
                  path={"/portal/refer-a-facility"}
                  element={
                    <ProtectedRoute>
                      {" "}
                      <ReferAFacility />{" "}
                    </ProtectedRoute>
                  }
                />
                {/* <Route
                  path={"/portal/chat"}
                  element={
                    <ProtectedRoute>
                      {" "}
                      <Chats />{" "}
                    </ProtectedRoute>
                  }
                /> */}
              </Route>
              <Route path="*" element={<ErrorPageRoot />} />
            </Routes>
          </Suspense>
        </ReferralProvider>
      </div>
      {/* {isMobile ? <BottomMenu /> : null} */}
    </div>
  );
};

export default AppLayout;
