import { colors, flex, fonts, textSizes } from "@styles/vars";
import { darken } from "polished";
import styled from "styled-components";

export const FlexCenterBetween = styled.div`
  padding: 10px 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen {
    flex-wrap: wrap;
  }
`;

export const FlexCenterAround = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  & > * {
    margin: 0 10px;
  }
`;
export const FlexCenterCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & > * {
    margin: 0 10px;
  }
`;

export const PrimaryButton = styled.button`
  border-radius: 8px;
  background-color: ${colors.blue};
  color: #fff;
  font-size: ${textSizes["14"]};
  font-family: ${fonts.accent};
  height: 40px;
  width: auto;
  min-width: 250px;
  display: flex;
  ${flex.center};
  gap: 8px;
  line-height: 1;

  transition: background-color var(--transition);
  @media screen and (max-width: 920px) {
    min-width: unset;
    width: 100%;
    margin: 5px;
  }

  &:hover,
  &:focus {
    background-color: ${darken(0.1, colors.blue)};
  }

  &.success {
    background-color: ${colors.success};

    &:hover,
    &:focus {
      background-color: ${darken(0.1, colors.success)};
    }
  }

  &.error {
    background-color: ${colors.error};

    &:hover,
    &:focus {
      background-color: ${darken(0.1, colors.error)};
    }
  }

  &.disabled {
    background-color: ${colors.gray};
    pointer-events: none;
  }
`;
