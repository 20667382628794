import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { cleanLocalStorage, removeLocalStorageItem } from "./helper";
import { useDispatch } from "react-redux";
import { logoutUser } from "@store/userSlice";

export const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(logoutUser());
    const logout = async () => {
      await removeLocalStorageItem();
      await cleanLocalStorage();
      navigate("/login");
    };
    logout();
  }, [navigate]);

  return null;
};
