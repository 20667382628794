import React, { useState, useEffect } from "react";
import { Form, Formik, ErrorMessage, Field } from "formik";
import { Link } from "react-router-dom";
import { Api } from "../../api/api";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SweetAlert from 'react-bootstrap-sweetalert';

const OPT = () => {
    const { isLoggedIn } = useSelector((state) => state.user);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        number: "",
        verify: false
    });
    const [errorMsg, setErrorMessage] = useState('');
    const [showErrorDialog, setShowErrorDialog] = useState(false);

    const [successMsg, setSuccessMessage] = useState('');
    const [showSuccessDialog, setShowSuccessDialog] = useState(false);
    const navigate = useNavigate();

    const validate = (values) => {
        const errors = {};

        if (!values.number) {
            errors.number = "Phone number is required";
        } else if (!/^\+1[0-9]{11}$/.test(values.number)) {
            errors.number = "Invalid phone number";
        }

        if (!values.verify) {
            errors.verify = "You must agree to receive SMS messages";
        }
        return errors;
    };

    const handleCloseError = () => {
        setShowErrorDialog(false);
    };

    const handleCloseSuccess = () => {
        return navigate("/");
    };

    const onSubmit = (values, { setSubmitting }) => {
        setLoading(true);
        setFormData(values);
        let data = new FormData();
        data.append("number", values.number);
        Api.opt(data)
            .then((res) => {
                setLoading(false);
                if (res?.status === 200) {
                    setLoading(false);
                    setSuccessMessage(res.data.message);
                    setShowSuccessDialog(true);
                }
            })
            .catch((res) => {
                setErrorMessage(res.response?.data?.message);
                setLoading(false);
                setShowErrorDialog(true);
            });
    };
    return (
        <>
            <section>
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto mt-5 mb-5 lg:py-0">
                    <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                Sign Up for SMS Notifications
                            </h1>
                            <Formik
                                initialValues={formData}
                                validate={validate}
                                onSubmit={onSubmit}
                            >
                                {({ isSubmitting, errors, touched, handleChange }) => (
                                    <Form className="space-y-4 md:space-y-6">
                                        <div>
                                            <div className="flex">
                                                <span className="inline-flex items-center px-3 text-sm text-gray-900 border-1 rounded-l-lg dark:border-gray login-input-span">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 512 512">
                                                        <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                                                    </svg>
                                                </span>
                                                <Field
                                                    as={"input"}
                                                    type={"text"}
                                                    id={"number"}
                                                    name={"number"}
                                                    autoComplete="off"
                                                    placeholder={"Your phone number"}
                                                    className="rounded-r-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 login-input"
                                                />
                                            </div>
                                            <ErrorMessage name={"number"}>
                                                {(msg) => (
                                                    <div className="text-red-500 text-sm mt-1">{msg}</div>
                                                )}
                                            </ErrorMessage>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <div className="flex items-start">
                                                <div className="flex items-center h-5">
                                                    <Field
                                                        name="verify"
                                                        id="verify"
                                                        aria-describedby="verify"
                                                        type="checkbox"
                                                        // onChange={(e) => { e.target.checked ? errors.verify = "This  field is required" : errors.verify = "" }}
                                                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                                                    />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label
                                                        htmlFor="verify"
                                                        className="text-gray-500 dark:text-gray-300"
                                                    >
                                                        I agree to receive SMS messages from Healthcare Referral Pro
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <ErrorMessage name={"verify"}>
                                            {(msg) => (
                                                <div className="text-red-500 text-sm mt-1">{msg}</div>
                                            )}
                                        </ErrorMessage>
                                        {loading ? (
                                            <div className="flex justify-center items-center">
                                                <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-gray-900"></div>
                                            </div>
                                        ) : (
                                            <button
                                                type="submit"
                                                className="w-full text-white bg-primary-500 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                                            >
                                                Submit
                                            </button>
                                        )}
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
                <SweetAlert
                    error
                    show={showErrorDialog}
                    title={errorMsg}
                    onConfirm={handleCloseError}
                    confirmBtnCssClass="bg-primary-500 rounded-lg text-xl text-white hover:bg-blue-600"
                />
                <SweetAlert
                    success
                    show={showSuccessDialog}
                    title={successMsg}
                    onConfirm={handleCloseSuccess}
                    confirmBtnCssClass="bg-primary-500 rounded-lg text-xl text-white hover:bg-blue-600"
                />
            </section>
        </>
    );
};
export default OPT;
