import {
  Box,
  Fab,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState, useRef, useEffect, useCallback } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  LinkSimple,
  PaperPlaneTilt,
  Smiley,
  Image,
  File,
} from "phosphor-react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
// import docImage from '../../assets/google-docs.png';
import txtImage from '../../assets/images/txt.png';
import docImage from '../../assets/images/doc.png';
import xlsImage from '../../assets/images/xls.png';
import pdfImage from '../../assets/images/pdf.png';
import toast from "react-hot-toast";
// import styled from "styled-components/macro";
import CircularProgress from "@mui/material/CircularProgress";
import { colors } from "@styles/vars";

const StyledInput = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    paddingTop: "12px",
    paddingBottom: "12px",
  },
  ".MuiFilledInput-root": {
    paddingTop: "5px",
    paddingBottom: "5px",
  },
}));

const ContainerLoader = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  paddingRight: '40px',
  position: 'absolute',
  left: '5%',
  top: '35%'
}));

const Actions = [
  {
    color: "#4da5fe",
    icon: <Image size={24} />,
    y: 102,
    title: "Photo/Video",
  },
  {
    color: "#0159b2",
    icon: <File size={24} />,
    y: 172,
    title: "Document",
  },
];

const ChatInput = ({ message, setMessage, setOpenPicker, fileInputRef, sendMessage, setOpenFilePicker, openFilePicker, docInputRef }) => {
  const [openAction, setOpenAction] = useState(openFilePicker);

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleDocClick = () => {
    docInputRef.current.click();
  }

  useEffect(() => {
    setOpenAction(openFilePicker);
  }, [openFilePicker])
  // Handle key press event to send message on Enter
  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    }
  };

  return (
    <StyledInput
      fullWidth
      multiline
      value={message}
      onChange={(e) => setMessage(e.target.value)}
      onKeyPress={handleKeyPress}
      placeholder="Write a message..."
      variant="filled"
      InputProps={{
        disableUnderline: true,
        startAdornment: (
          <Stack sx={{ width: "max-content" }}>
            <Stack
              sx={{
                position: "relative",
                display: openAction ? "inline-block" : "none",
              }}
            >
              {Actions.map((el, index) => (
                <Tooltip key={index} placement="right" title={el.title}>
                  <Fab
                    sx={{
                      position: "absolute",
                      top: -el.y,
                      backgroundColor: el.color,
                    }}
                    onClick={el.title === "Document" ? handleDocClick : handleFileClick}
                  >
                    {el.icon}
                  </Fab>
                </Tooltip>
              ))}
            </Stack>
            <InputAdornment>
              <IconButton
                onClick={() => {
                  setOpenAction((prev) => !prev);
                  setOpenFilePicker((prv) => !prv);
                }}
              >
                <LinkSimple />
              </IconButton>
            </InputAdornment>
          </Stack>
        ),
        endAdornment: (
          <InputAdornment>
            <IconButton
              onClick={() => {
                setOpenPicker((prev) => !prev);
              }}
            >
              <Smiley />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

const Footer = ({ editMessage, titleAction, userId, handleSendMessage, uplaodLoader }) => {
  const theme = useTheme();
  const [openPicker, setOpenPicker] = useState(false);
  const [message, setMessage] = useState("");
  const [replyMessage, setReplayMessage] = useState("");
  const [editMessageDetail, setEidtMessageDetail] = useState("");
  const [files, setFile] = useState("");
  const [openFilePicker, setOpenFilePicker] = useState(false);
  const fileInputRef = useRef(null);
  const docInputRef = useRef(null);
  const pickerRef = useRef(null); // Reference for the emoji picker
  const { user } = useSelector((state) => state.user);
  const [sendLoader, setLoader] = useState(false);

  const handleEmojiSelect = (emoji) => {
    setMessage((prevMessage) => prevMessage + emoji.native);
  };
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'svg'];

  const videoExtensions = ['mp4', 'mov', 'wmv', 'avi', 'mkv', 'webm'];
  const allowExtentions = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'svg', 'pdf', 'txt', 'doc', 'docx', 'xls', 'xlsm', 'xlsx', 'csv'];

  const handleFileChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (allowExtentions.includes(file.name.split('.').pop().toLowerCase())) {
      file.url = URL.createObjectURL(file);
      file.extensions = file.name.split('.').pop().toLowerCase();
      setFile(file);
      setOpenFilePicker(false);
    }
    else {
      toast.error(`.${file.name.split('.').pop().toLowerCase()} this extention is not allow.`);
      fileInputRef.current.value = '';
      docInputRef.current.value = '';
    }
    // Handle the file upload here
  };
  const handleFilesCloseMessage = (event) => {
    event.preventDefault();
    fileInputRef.current.value = '';
    docInputRef.current.value = '';
    setFile('');
  }
  const handleCloseMessage = () => {
    setReplayMessage("");
  };

  const handleEditCloseMessage = () => {
    setMessage("");
    setEidtMessageDetail("");
  }

  const handleClickOutside = useCallback((event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setOpenPicker(false); // Close the picker if clicked outside
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    setLoader(uplaodLoader)
    if (!uplaodLoader) {
      fileInputRef.current.value = '';
      docInputRef.current.value = '';
      setFile('');
    }
  }, [uplaodLoader])

  useEffect(() => {
    if (titleAction === "Edit") {
      setMessage(editMessage?.message);
      setEidtMessageDetail(editMessage)
    } else {
      setMessage("");
      setEidtMessageDetail("")
      setReplayMessage(editMessage);
    }
  }, [editMessage, titleAction]);

  const sendMessage = async () => {
    if (files) {
      handleSendMessage({
        parent_chat_id: replyMessage?.id ? replyMessage?.id : '',
        sender_id: user.id,
        reciver_id: userId,
        message: message,
        message_type: imageExtensions.includes(files.extensions) ? 'image' : 'file',
        id: editMessageDetail?.id,
        file: files
      })
      fileInputRef.current.value = '';
      docInputRef.current.value = '';
    }
    else {
      handleSendMessage({
        parent_chat_id: replyMessage?.id ? replyMessage?.id : '',
        sender_id: user.id,
        reciver_id: userId,
        message: message,
        message_type: 'text',
        id: editMessageDetail?.id
      })
    }
    setMessage("");
    setEidtMessageDetail("");
    setReplayMessage("");
  }

  return (
    <Box
      p={2}
      sx={{
        position: "absolute",
        bottom: 0,
        right: 0,
        width: "100%",
        backgroundColor:
          theme.palette.mode === "light"
            ? "#F8FAFF"
            : theme.palette.background.paper,
        boxShadow: "0px 0px 2px rgba(0,0,0,0.25)",
      }}
    >
      {replyMessage && (
        <Box
          sx={{
            position: "relative",
            p: 2,
            border: "1px solid #ccc",
            borderRadius: "8px",
            mb: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
            {
              replyMessage?.message_type == 'image' ?
                (
                  <Typography variant="body2" sx={{ flexGrow: 1, color: "gray" }} style={{ opacity: `${sendLoader ? '0.5' : '1'}` }}>
                    <Typography style={{ display: 'flex', alignItems: 'center' }}>
                      Replying to:
                      <img src={replyMessage?.attechment_url} alt="" style={{ width: '50px', height: '50px', borderRadius: '10px', marginLeft: '10px' }} />
                      <Typography sx={{ ml: 2 }}>{replyMessage?.message}</Typography>
                    </Typography>
                  </Typography>
                )
                :
                replyMessage?.message_type == 'file' ?
                  (
                    <Typography variant="body2" sx={{ flexGrow: 1, color: "gray" }} style={{ opacity: `${sendLoader ? '0.5' : '1'}` }}>
                      <Typography style={{ display: 'flex', alignItems: 'center' }}>
                        Replying to:
                        <img src={
                          replyMessage?.attachment_name.split('.').pop().toLowerCase() == 'pdf' ?
                            pdfImage
                            :
                            replyMessage?.attachment_name.split('.').pop().toLowerCase() == 'txt' ?
                              txtImage :
                              replyMessage?.attachment_name.split('.').pop().toLowerCase() == 'xls' && replyMessage?.attachment_name.split('.').pop().toLowerCase() == 'xlsm' && replyMessage?.attachment_name.split('.').pop().toLowerCase() == 'xlsx' && replyMessage?.attachment_name.split('.').pop().toLowerCase() == 'csv' ?
                                xlsImage : docImage
                        } alt="" style={{ width: '50px', height: '50px', borderRadius: '10px', marginLeft: '10px' }} />
                        <Typography sx={{ ml: 2 }}>{replyMessage?.message}</Typography>
                      </Typography>
                    </Typography>
                  )
                  :
                  (
                    <Typography variant="body2" sx={{ flexGrow: 1, color: "gray" }}>
                      Replying to: {replyMessage?.message}
                    </Typography>
                  )
            }

            <IconButton size="small" onClick={handleCloseMessage}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      )
      }

      {
        editMessageDetail && (
          <Box
            sx={{
              position: "relative",
              p: 2,
              border: "1px solid #ccc",
              borderRadius: "8px",
              mb: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Typography variant="body2" sx={{ flexGrow: 1, color: "gray" }}>
                Edit to: {editMessageDetail?.message}
              </Typography>
              <IconButton size="small" onClick={handleEditCloseMessage}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        )
      }

      {
        files && (
          <Box
            sx={{
              position: "relative",
              p: 2,
              border: "1px solid #ccc",
              borderRadius: "8px",
              mb: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", mb: 1, position: 'relative' }}>
              <Typography variant="body2" sx={{ flexGrow: 1 }} style={{ opacity: `${sendLoader ? '0.5' : '1'}` }}>
                <a href={files.url} target="_blank" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                  {
                    imageExtensions.includes(files.extensions) ?
                      (
                        <img src={files.url} alt={files.name} style={{ width: '100px', height: '100px', borderRadius: '10px' }} />
                      )
                      :
                      videoExtensions.includes(files.extensions) ?
                        (
                          <video width="100" height="100" controls>
                            <source src={files.url} type={`video/${files.extensions}`} />
                          </video>
                        )
                        :
                        (
                          <img src={
                            files.extensions == 'pdf' ?
                              pdfImage
                              :
                              files.extensions == 'txt' ?
                                txtImage
                                :
                                files.extensions == 'xls' && files.extensions == 'xlsm' && files.extensions == 'xlsx' && files.extensions == 'csv' ?
                                  xlsImage
                                  : docImage
                          } alt={files.name} style={{ width: '100px', height: '100px', borderRadius: '10px' }} />
                        )
                  }
                  <Typography sx={{ ml: 2 }}>{files.name}</Typography>
                </a>
              </Typography>
              {
                sendLoader && (
                  <ContainerLoader>
                    <div>
                      <CircularProgress
                        variant="indeterminate"
                        size={40}
                        thickness={2}
                        value={100}
                        aria-busy={true}
                        sx={{
                          color: colors.blue,
                        }}
                      />
                    </div>
                  </ContainerLoader>
                )
              }
              <IconButton size="small" onClick={(e) => { handleFilesCloseMessage(e) }}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        )
      }

      <Stack direction="row" alignItems={"center"} spacing={3}>
        <Stack sx={{ width: "100%" }}>
          {/* Chat Input */}
          <Box
            ref={pickerRef} // Reference the picker container
            sx={{
              display: openPicker ? "inline" : "none",
              zIndex: 10,
              position: "fixed",
              bottom: 81,
              right: 100,
            }}
          >
            <Picker
              theme={theme.palette.mode}
              data={data}
              onEmojiSelect={handleEmojiSelect}
            />
          </Box>
          <ChatInput
            message={message}
            setMessage={setMessage}
            setOpenPicker={setOpenPicker}
            fileInputRef={fileInputRef}
            sendMessage={sendMessage}  // Pass the sendMessage function to ChatInput
            setOpenFilePicker={setOpenFilePicker}
            openFilePicker={openFilePicker}
            docInputRef={docInputRef}
          />
        </Stack>

        <Box
          sx={{
            height: 48,
            width: 48,
            backgroundColor: theme.palette.primary.main,
            borderRadius: 1.5,
          }}
        >
          <Stack
            sx={{
              height: "100%",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton onClick={sendMessage}>
              <PaperPlaneTilt color="#fff" />
            </IconButton>
          </Stack>
        </Box>
      </Stack>
      <input
        type="file"
        ref={docInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept=".pdf,.txt,.doc,.docx,.xls,.xlsm,.xlsx,.csv"
      />

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept=".jpg,.jpeg,.png,.gif,.webp,.svg"
      />
    </Box >
  );
};

export default Footer;
