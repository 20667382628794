import { ErrorMessage, Field } from "formik";
import PropTypes from "prop-types";
import { propTypes } from "react-bootstrap/esm/Image";

const CustomInput = ({ label, type, name, readonly, ...rest }) => {
  const inputComponent = type === "textarea" ? "textarea" : "input";

  return (
    <div className="mb-3 mx-3">
      <label htmlFor={name} className="block text-base font-bold text-gray-700">
        {label}
      </label>
      <Field
        as={inputComponent}
        type={type ? type : "text"}
        id={name}
        name={name}
        autoComplete="off"
        placeholder={label}
        readOnly={readonly}
        {...rest}
        className={`border border-gray-300 text-gray-900 text-base rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${readonly ? 'bg-gray-400' : 'bg-white-50'}`}
      />
      <ErrorMessage name={name}>
        {(msg) => <div className="text-red-500 text-sm mt-1">{msg}</div>}
      </ErrorMessage>
    </div>
  );
};

CustomInput.propTypes = {
  label: PropTypes.string.isRequired,
  type: propTypes.string,
  name: PropTypes.string.isRequired,
};

export default CustomInput;
