import { Box, Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Header from "./Header";
import Footer from "./Footer";
import Message from "./Message";
import { Api } from "api/api";
import SweetAlert from 'react-bootstrap-sweetalert';

const Conversation = ({
  userId,
  userName,
  socket,
  setToggle,
  toggle,
  setSelectedUser,
}) => {
  const [messageAction, setMessageAction] = useState("");
  const [titleAction, setTitleAction] = useState("");
  const [messageList, setMessageList] = useState([]);
  const { user } = useSelector((state) => state.user);
  const [uplaodLoader, setUploadLoader] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const getConversionList = async () => {
    if (userId) {
      const res = await Api.getUserConversionList(userId);
      setMessageList(res?.data?.data?.items?.reverse());
    }
  };

  useEffect(() => {
    getConversionList();
  }, [userId]);

  useEffect(() => {
    if (socket?.id) {
      if (userId && user?.id) {
        socket.emit("read_receipts", { 'sender_id': user?.id, 'reciver_id': userId });
        socket.emit("get_user_list", {
          login_id: user?.id
        });
        socket.emit("unread_user_count", {
          login_id: user.id,
        });
        // console.log("L-35 read_receipts", { 'sender_id' : user?.id, 'reciver_id' : userId});
        // socket.on("get_user_list", { 'sender_id' : user.id});
      }
    }
  }, [titleAction, userId]);

  // Set up socket listener for incoming messages
  useEffect(() => {
    if (!user) {
      return; // Don't set up socket if user is null
    }

    const handleMessageReceive = (data) => {
      console.log('data', data);
      if (userId && (data.sender_id === userId || data.reciver_id === userId)) {
        setMessageList((prevMessageList) => [...prevMessageList, data]);
      }
    };

    const handleMessageDelete = (data) => {
      if (userId && (data.sender_id === userId || data.reciver_id === userId)) {
        setMessageList((prevMessageList) =>
          prevMessageList.filter((msg) => msg.id !== data.id)
        );
      }
    };

    const handleEditMessage = (data) => {
      if (userId && (data.sender_id === userId || data.reciver_id === userId)) {
        setMessageList((prevMessageList) => {
          const tempMessage = [...prevMessageList];
          let msgIndex = tempMessage.findIndex((msg) => msg.id === data.id);

          if (msgIndex !== -1) {
            tempMessage[msgIndex] = {
              ...tempMessage[msgIndex],
              message: data.message,
            }; // Update the message
          }
          return tempMessage;
        });
      }
    };

    // Listen for messages sent to this user
    socket.on(`sendto_${userId}`, handleMessageReceive);
    socket.on(`sendto_${user.id}`, handleMessageReceive);
    socket.on(`editto_${userId}`, handleEditMessage);
    socket.on(`editto_${user.id}`, handleEditMessage);
    socket.on(`deleted_${userId}`, handleMessageDelete);
    socket.on(`deleted_${user.id}`, handleMessageDelete);
    socket.on(`attechment_sendto_${userId}`, handleMessageReceive);
    socket.on(`attechment_sendto_${user.id}`, handleMessageReceive);

    // Clean up the listener when the component unmounts or when userId changes
    return () => {
      socket.off(`sendto_${userId}`, handleMessageReceive);
      socket.off(`sendto_${user.id}`, handleMessageReceive);
      socket.off(`editto_${userId}`, handleEditMessage);
      socket.off(`editto_${user.id}`, handleEditMessage);
      socket.off(`deleted_${userId}`, handleMessageDelete);
      socket.off(`deleted_${user.id}`, handleMessageDelete);
      socket.off(`attechment_sendto_${userId}`, handleMessageReceive);
      socket.off(`attechment_sendto_${user.id}`, handleMessageReceive);
      setMessageList([]);
    };
  }, [socket, userId, user]);

  // Handle sending messages
  const handleSendMessage = async (message) => {
    if (message.message_type == 'text') {
      if (message?.message?.trim()) {
        if (message?.id && message?.id !== "") {
          socket.emit("chat_message_edit", message);
        } else {
          socket.emit("chat_message", message);
        }
      }
    }
    else {
      setUploadLoader(true);
      console.log('user.id', user.id, userId);
      const formData = new FormData();
      formData.append("attachment", message.file);
      formData.append("message", message.message)
      formData.append("id", userId);
      formData.append("parent_chat_id", message.parent_chat_id);
      try {
        const res = await Api.sendAttachment(formData);
        if (res?.status === 200) {
          socket.emit(`attechment_send`, res?.data?.data);
          setUploadLoader(false);
        }
      } catch (error) {
        console.log('errror', error);
        setUploadLoader(false);
      }
    }

    socket.emit("get_user_list", {
      login_id: user.id
    });
    socket.emit("get_user_list", {
      login_id: userId
    });
    socket.emit("unread_user_count", {
      login_id: user.id,
    });
    socket.emit("unread_user_count", {
      login_id: userId,
    });
  };

  // Handle setting message and title actions
  const handleMessage = (message, title) => {
    if (title !== "Delete Message") {
      setMessageAction(message);
      setTitleAction(title);
    } else {
      setDeleteData({
        id: message.id,
        sender_id: message.sender_id,
        reciver_id: message.reciver_id,
      })
      setShowDialog(true)
    }
  };

  const handleYes = () => {
    socket.emit("chat_message_delete", deleteData);
    setShowDialog(false)
  }

  const handleCancel = () => {
    setShowDialog(false)
  }

  useEffect(() => {
    socket.emit("read_receipts", {
      sender_id: user.id,
      reciver_id: userId
    })
  }, [userId])

  return (
    <Stack
      height={"100%"}
      maxHeight={"100%"}
      width={"auto"}
      sx={{ position: "relative" }}
    >
      {/* Chat header */}
      {userId && (
        <Header
          userName={userName}
          setToggle={setToggle}
          toggle={toggle}
          getConversionList={getConversionList}
          userId={userId}
          setSelectedUser={setSelectedUser}
        />
      )}

      {/* Message list */}
      <Box
        className="scrollbar"
        width={"100%"}
        sx={{
          flexGrow: 1,
          height: "100%",
          overflowY: "scroll",
          boxShadow: "0px 0px 2px rgba(0,0,0,0.25)",
          minHeight: userId ? "calc(80vh-162px)" : "82vh",
          maxHeight: "100%",
        }}
      >
        <Message
          menu={true}
          handleMessage={handleMessage}
          chatHistory={messageList}
          userId={userId}
        />
      </Box>

      {/* Chat footer */}
      {userId && (
        <Footer
          editMessage={messageAction}
          titleAction={titleAction}
          userId={userId}
          handleSendMessage={handleSendMessage}
          uplaodLoader={uplaodLoader}
        />
      )}
      <SweetAlert
        warning
        show={showDialog}
        title="Are you sure you want to delete ?"
        showCancel={true}
        confirmBtnText={'Yes'}
        cancelBtnText={'Cancel'}
        onConfirm={handleYes}
        onCancel={handleCancel}
        confirmBtnCssClass="bg-primary-500 rounded-lg text-xl text-white hover:bg-blue-600"
        cancelBtnCssClass="bg-red-500 rounded-lg text-xl text-white hover:bg-red-600"
      />
    </Stack>
  );
};

export default Conversation;
