// styling
import styled from "styled-components/macro";
import { flex, textSizes } from "@styles/vars";
// utils
import PropTypes from "prop-types";
import InputField from "@ui/Field";
import { ErrorMessage } from "formik";
import CircularProgress from '@mui/material/CircularProgress';
import React from "react";

const Wrapper = styled.div`
  ${flex.col};
`;

const Label = styled.label`
  font-size: ${textSizes["14"]};
  width: fit-content;
  margin-bottom: 8px;
`;

const LabeledFormInput = ({
  id,
  name,
  title,
  type,
  placeholder,
  value,
  customInput,
  handler,
  onKeyPress,
  loading
}) => {
  return (
    <Wrapper style={{ position: 'relative' }}>
      <Label htmlFor={id}>{title}</Label>
      {customInput ? (
        <>
          {customInput}
          <ErrorMessage
            name={name}
            component="div"
            style={{ color: "red", fontSize: "16px", padding: "5px" }}
          />
        </>
      ) : (
        <>
          <InputField
            type={type ? type : "text"}
            name={name}
            // defaultValue={value}
            value={value}
            id={name}
            placeholder={placeholder}
            handler={handler}
            onKeyPress={onKeyPress}
            loading={loading}
          />
          <ErrorMessage
            name={name}
            component="div"
            style={{ color: "red", fontSize: "16px", padding: "5px" }}
          />
        </>
      )}
      {(loading && loading == true) ? <CircularProgress color="inherit" size={20} style={{ position: 'absolute', right: '35px', top: '50%' }} /> : null}
    </Wrapper>
  );
};

LabeledFormInput.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["text", "password", "email", "number"]),
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customInput: PropTypes.element,
  handler: PropTypes.func,
  onKeyPress: PropTypes.func,
};

export default LabeledFormInput;
