// styled components
import { Menu, UserWrapper } from "../style";

// components
import Avatar from "@ui/Avatar";

// utils
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { useState } from "react";

// assets
import doc1jpg from "@assets/avatars/doc1.jpg";
import doc1webp from "@assets/avatars/doc1.jpg?as=webp";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

const CurrentUser = () => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleClickAway = () => setOpen(false);
  const handleClick = () => setOpen(!open);
  const src = {
    jpg: doc1jpg,
    webp: doc1webp,
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <UserWrapper>
        {/* <Avatar avatar={src} alt="avatar" /> */}
        <div className="info">
          <span className="h3">{user?.contact_name}</span>
          <span className="position">{user?.parent_delegate_access_user?.length !== 0 ? user?.parent_delegate_access_user?.facility_name : user.facility_name}</span>
          <Menu className={open ? "visible" : ""}>
            {
              user?.role !== 'delegate_access' ? (
                <>
                  <button onClick={() => navigate("/portal/profile")}>
                    <i className="icon icon-circle-user" /> Profile
                  </button>
                  <button onClick={() => navigate("/logout")}>
                    <i className="icon icon-logout" /> Logout
                  </button>
                </>
              )
                :
                (<button onClick={() => navigate("/logout")}>
                  <i className="icon icon-logout" /> Logout
                </button>

                )
            }
          </Menu>
        </div>
        <button
          className="trigger"
          onClick={handleClick}
          aria-label="Show menu"
        >
          <i className="icon icon-chevron-down" />
        </button>
      </UserWrapper>
    </ClickAwayListener>
  );
};

export default CurrentUser;
