import { Box, Stack, Typography, Badge } from "@mui/material";
import { useTheme } from "@mui/material/styles";

//single chat element
const ChatElement = ({
  id,
  name,
  setSelectedUser,
  setUserName,
  setToggle,
  selectedUser,
  unread_count,
}) => {
  const theme = useTheme();
  const handleUser = () => {
    setSelectedUser(id);
    setUserName(name);
    setToggle(false);
  };
  return (
    <Box
      sx={{
        cursor: "pointer",
        width: "100%",
        borderRadius: 1,
        color: selectedUser === id ? "white" : "",
        backgroundColor:
          selectedUser === id ? "blue" : theme.palette.background.default,
        marginTop: "5px !important"
      }}
      p={1}
      onClick={handleUser}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={2}>
          {/* {online ? <StyledBadge overlap='circular' anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot">
                        <Avatar src={img} />
                    </StyledBadge> : 
                    } */}
          {/* <Avatar src={img} /> */}

          <Stack spacing={0.5}>
            <Typography variant="subtitle2">{name}</Typography>
            {/* <Typography variant='caption'>
                            {msg}
                        </Typography> */}
          </Stack>
        </Stack>
        <Stack spacing={2} alignItems="center" style={{ marginRight: "10px" }}>
          {/* <Typography sx={{ fontWeight: 600 }} variant='caption'>
            {time}
          </Typography> */}
          <Badge color="primary" badgeContent={unread_count}></Badge>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ChatElement;
