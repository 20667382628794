import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "@store/store";
import { BrowserRouter } from "react-router-dom";
import OldRoute from "old-route";
import RouteConfig from "frontend/routing/routeConfig";
import { Toaster } from "react-hot-toast";
import { FieldProvider } from "@contexts/fieldContext";

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <FieldProvider>
      <BrowserRouter>
        <RouteConfig />
        <OldRoute />
        <Toaster
          containerClassName="custom_toaster"
          position="top-right"
          toastOptions={{
            className: "",
            style: {
              padding: "10px 15px",
              color: "white",
              backgroundColor: "blue",
            },
          }}
        />
      </BrowserRouter>
    </FieldProvider>
  </Provider>
);
