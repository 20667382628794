import {
  Box,
  Typography,
  IconButton,
  Stack,
  Menu,
  Fade,
  MenuItem,
} from "@mui/material";
import { ArrowLeft, CaretDown } from "phosphor-react";
import React from "react";
import { useTheme } from "@mui/material/styles";
import { Api } from "api/api";

const Conversation_Menu = [
  {
    title: "Clear messages",
  },
];

const Header = ({
  userName,
  setToggle,
  toggle,
  getConversionList,
  userId,
  setSelectedUser,
}) => {
  const theme = useTheme();

  const [conversationMenuAnchorEl, setConversationMenuAnchorEl] =
    React.useState(null);
  const openConversationMenu = Boolean(conversationMenuAnchorEl);
  const handleClickConversationMenu = (event) => {
    setConversationMenuAnchorEl(event.currentTarget);
  };
  const handleCloseConversationMenu = () => {
    setConversationMenuAnchorEl(null);
  };

  const handleHeaderMenu = async (el) => {
    if (el.title === "Clear messages") {
      const formData = new FormData();
      formData.append("reciver_id", userId);
      Api.clearChatMessage(formData).then((res) => {
        getConversionList();
      });
    }
    setConversationMenuAnchorEl(null);
  };

  const handleToggle = () => {
    setToggle(!toggle);
    if (userId) {
      setSelectedUser(null);
    }
  };
  return (
    <Box
      p={2}
      sx={{
        width: "100%",
        backgroundColor:
          theme.palette.mode === "light"
            ? "#F8FAFF"
            : theme.palette.background.paper,
        boxShadow: "0px 0px 2px rgba(0,0,0,0.25)",
      }}
    >
      <Stack
        alignItems={"center"}
        direction="row"
        justifyContent={"space-between"}
        sx={{ width: "100%", height: "100%" }}
      >
        <Stack direction={"row"} spacing={2}>
          <Box>
            <IconButton>
              <ArrowLeft onClick={handleToggle} />
            </IconButton>
            {/* <StyledBadge overlap="circular"
                            anchorOrigin={{ // position
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            variant="dot">
                        </StyledBadge> */}
            {/* <Avatar alt={faker.name.fullName()} src={faker.image.avatar()} /> */}
          </Box>
          <Stack spacing={0.2}>
            <Typography variant="subtitle2" style={{ marginTop: "10px" }}>
              {userName}
            </Typography>
            {/* <Typography variant='caption'>
                            Online
                        </Typography> */}
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={3}>
          {/* <IconButton>
                        <VideoCamera />
                    </IconButton>
                    <IconButton>
                        <Phone />
                    </IconButton>
                    <IconButton>
                        <MagnifyingGlass />
                    </IconButton> */}
          {/* <Divider orientation='vertical' flexItem /> */}
          {/* <IconButton>
            <CaretDown />
          </IconButton> */}
          <IconButton
            id="conversation-positioned-button"
            aria-controls={
              openConversationMenu ? "conversation-positioned-menu" : undefined
            }
            aria-haspopup="true"
            aria-expanded={openConversationMenu ? "true" : undefined}
            onClick={handleClickConversationMenu}
          >
            <CaretDown />
          </IconButton>
          <Menu
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            TransitionComponent={Fade}
            id="conversation-positioned-menu"
            aria-labelledby="conversation-positioned-button"
            anchorEl={conversationMenuAnchorEl}
            open={openConversationMenu}
            onClose={handleCloseConversationMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Box p={1}>
              <Stack spacing={1}>
                {Conversation_Menu.map((el) => (
                  <MenuItem onClick={() => handleHeaderMenu(el)}>
                    <Stack
                      sx={{ minWidth: 100 }}
                      direction="row"
                      alignItems={"center"}
                      justifyContent="space-between"
                    >
                      <span>{el.title}</span>
                    </Stack>{" "}
                  </MenuItem>
                ))}
              </Stack>
            </Box>
          </Menu>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Header;
