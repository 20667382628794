import React, { useEffect, useState } from "react";
import { Api } from "../../api/api";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../helper/utils";
import { useDispatch } from "react-redux";
import { setUser } from "@store/userSlice";
import SweetAlert from 'react-bootstrap-sweetalert';

const Subscription = () => {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchPlans();
  }, []);

  const fetchPlans = () => {
    Api.getPlans().then((res) => {
      if (res?.status === 200) {
        setPlans(res?.data?.data);
      }
    });
  };
  const handleBuyNow = (id) => {
    setLoading(true);
    navigate("/checkout", { state: { planId: id } });

    let data = new FormData();
    data.append("membership_id", id);
    // Api.updateMembership(data)
    //   .then((res) => {
    //     if (res?.status === 200) {
    //       setLoading(false);
    //       setShowDialog(true);
    //     }
    //   })
    //   .catch((res) => {
    //     setShowErrorDialog(true);
    //     setLoading(false);
    //   });
  };

  const handleClose = () => {
    setShowDialog(false);
    Api.getProfile().then((userRes) => {
      dispatch(setUser(userRes.data.data));
      navigate("/portal/dashboard");
    });
  };
  const handleCloseError = () => {
    setShowErrorDialog(false);
  };

  ScrollToTop();

  return (
    <>
      <section className="bg-white formpart">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">
              Choose a Subscription Plan
            </h2>
          </div>
          {/* <div className="space-y-8 lg:grid lg:grid-cols-2 sm:gap-6 xl:gap-10 lg:space-y-0"> */}
          <div className="flex">
            {plans?.map((item, key) => {
              return (
                <div
                  key={key}
                  className="flex flex-col w-50 p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-black-500 shadow xl:p-8"
                >
                  <h3 className="mb-4 text-2xl font-semibold">
                    {item?.subscription_plan_name}
                  </h3>
                  <div className="flex justify-center items-baseline my-2">
                    <span className="mr-2 text-3xl font-extrabold">
                      {item?.subscription_plan_price}
                    </span>
                    <span className="text-gray-500">/price</span>
                  </div>
                  <div className="flex justify-center items-baseline my-4">
                    <span className="mr-2 text-3xl font-extrabold">
                      {item?.subscription_plan_validity}
                    </span>
                    <span className="text-gray-500">/days</span>
                  </div>
                  <ul role="list" className="mb-8 space-y-4 text-left">
                    {item?.new_content.map((content, i) => (
                      <li key={i} className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>{content}</span>
                      </li>
                    ))}
                  </ul>
                  {loading ? (
                    <div className="flex justify-center items-center">
                      <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-gray-900"></div>
                    </div>
                  ) : (
                    <button
                      onClick={() => handleBuyNow(item?.id)}
                      id={item?.id}
                      className="text-white bg-primary-500 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                      Try Now
                    </button>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div>
          <SweetAlert
            success
            show={showDialog}
            title="Thanks for your subscription select a plan."
            onConfirm={handleClose}
            confirmBtnCssClass="bg-primary-500 rounded-lg text-xl text-white hover:bg-blue-600"
          />
          <SweetAlert
            error
            show={showErrorDialog}
            title={'System error.'}
            onConfirm={handleCloseError}
            confirmBtnCssClass="bg-primary-500 rounded-lg text-xl text-white hover:bg-blue-600"
          />
        </div>
      </section>
    </>
  );
};

export default Subscription;
