import {
  Box,
  Divider,
  IconButton,
  Link,
  Stack,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DotsThreeVertical, DownloadSimple, Image } from "phosphor-react";
import React from "react";
import { Message_options } from "../../data";
import moment from "moment";
import txtImage from '../../assets/images/txt.png';
import docImage from '../../assets/images/doc.png';
import xlsImage from '../../assets/images/xls.png';
import pdfImage from '../../assets/images/pdf.png';
import DownloadIcon from "@mui/icons-material/Download";

const DocMsg = ({ el, menu, handleMessage, userId }) => {
  const theme = useTheme();

  const handleDownload = async (file) => {
    // const link = document.createElement("a");
    // link.href = file.attachment_url;
    // link.download = file.attachment_name || "download";
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);

    try {
      const response = await fetch(file.attachment_url, {
        mode: 'cors' // Ensures CORS issues are handled if needed
      });

      // Check if the response is OK
      if (!response.ok) {
        throw new Error(`Failed to download file: ${response.statusText}`);
      }

      const blob = await response.blob(); // Convert the response into a Blob
      const url = window.URL.createObjectURL(blob); // Create a URL for the Blob

      const link = document.createElement("a");
      link.href = url;
      link.download = file.attachment_name || "download"; // Set the file name
      document.body.appendChild(link);
      link.click();

      // Clean up after download
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url); // Release memory
    } catch (error) {
      console.error("Error downloading the file:", error);
      alert("Error downloading the file.");
    }
  };

  return (
    <Stack
      direction="row"
      justifyContent={el.reciver_id === userId ? "end" : "start"}
    >
      {
        el?.parent_chat ? (
          <Box
            p={1.5}
            sx={{
              backgroundColor:
                el.sender_id === userId
                  ? theme.palette.background.default
                  : theme.palette.primary.main,
              borderRadius: 1.5,
              width: "max-content",
            }}
          >
            <Stack spacing={2}>
              <Stack
                p={1}
                direction="column"
                spacing={1}
                alignItems="center"
                sx={{
                  backgroundColor:
                    el.sender_id === userId
                      ? theme.palette.primary.main
                      : theme.palette.background.default,
                  borderRadius: 1,
                }}
              >
                {
                  el?.parent_chat?.message_type == 'image' ?
                    (
                      <ImageReply el={el.parent_chat} userId={userId} />
                    )
                    :
                    el?.parent_chat?.message_type == 'file' ?
                      (
                        <DocReply el={el.parent_chat} userId={userId} sender_id={el.sender_id} />
                      )
                      :
                      (
                        <Typography variant="body2" color={theme.palette.text}>
                          {el?.parent_chat?.message}
                        </Typography>
                      )
                }
              </Stack>
              <Stack spacing={1}>
                <img src={
                  el?.attachment_name.split('.').pop().toLowerCase() == 'pdf' ?
                    pdfImage
                    :
                    el?.attachment_name.split('.').pop().toLowerCase() == 'txt' ?
                      txtImage :
                      el?.attachment_name.split('.').pop().toLowerCase() == 'xls' && el?.attachment_name.split('.').pop().toLowerCase() == 'xlsm' && el?.attachment_name.split('.').pop().toLowerCase() == 'xlsx' && el?.attachment_name.split('.').pop().toLowerCase() == 'csv' ?
                        xlsImage : docImage
                } alt="" style={{ width: '50px', height: '50px', borderRadius: '10px', marginLeft: '10px' }} />
                <Typography variant="caption">{el.attachment_name}</Typography>
                <IconButton >
                  <a href={el.attechment_url} target="_blank" download><DownloadIcon /></a>
                  {/* <DownloadSimple onClick={() => handleDownload(el)} /> */}
                </IconButton>
                <Typography sx={{ fontSize: '10px', textAlign: 'right', marginTop: '5px' }} color={el.sender_id === userId ? theme.palette.text : "#fff"}>
                  {moment(el?.created_at).format('hh:mm A, MMM DD, YYYY')}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        )
          :
          (
            <Box
              p={1.5}
              sx={{
                backgroundColor:
                  el.reciver_id !== userId
                    ? theme.palette.background.default
                    : theme.palette.primary.main,
                borderRadius: 1.5,
                width: "max-content",
              }}
            >
              <Stack spacing={2}>
                <Stack
                  p={2}
                  spacing={3}
                  direction="row"
                  alignItems="center"
                  sx={{
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: 1
                  }}
                >
                  {/* <Image size={48} /> */}
                  {
                    <img src={
                      el?.attachment_name.split('.').pop().toLowerCase() == 'pdf' ?
                        pdfImage
                        :
                        el?.attachment_name.split('.').pop().toLowerCase() == 'txt' ?
                          txtImage :
                          el?.attachment_name.split('.').pop().toLowerCase() == 'xls' && el?.attachment_name.split('.').pop().toLowerCase() == 'xlsm' && el?.attachment_name.split('.').pop().toLowerCase() == 'xlsx' && el?.attachment_name.split('.').pop().toLowerCase() == 'csv' ?
                            xlsImage : docImage
                    } alt="" style={{ width: '50px', height: '50px', borderRadius: '10px', marginLeft: '10px' }} />
                  }
                  <Typography variant="caption">{el.attachment_name}</Typography>
                  <IconButton >
                    <a href={el.attechment_url} target="_blank" download><DownloadIcon /></a>
                    {/* <DownloadSimple onClick={() => handleDownload(el)} /> */}
                  </IconButton>
                </Stack>
                {el.message && (
                  <Typography
                    variant="body2"
                    sx={{
                      color: el.reciver_id !== userId ? theme.palette.text : "#fff",
                    }}
                  >
                    {el.message}
                  </Typography>
                )}
                <Typography sx={{ fontSize: '10px', textAlign: 'right', marginTop: '5px' }} color={el.sender_id === userId ? theme.palette.text : "#fff"}>
                  {moment(el?.created_at).format('hh:mm A, MMM DD, YYYY')}
                </Typography>
              </Stack>
            </Box>
          )
      }

      {
        menu && (
          <MessageOptions messageDetails={el} handleMessage={handleMessage} userId={userId} />
        )
      }
    </Stack >
  );
};

const LinkMsg = ({ el, menu, handleMessage, userId }) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      justifyContent={el.reciver_id === userId ? "end" : "start"}
    >
      <Box
        p={1.5}
        sx={{
          backgroundColor:
            el.sender_id === userId
              ? theme.palette.background.default
              : theme.palette.primary.main,
          borderRadius: 1.5,
          width: "max-content",
        }}
      >
        <Stack spacing={2}>
          <Stack
            p={2}
            spacing={3}
            alignItems="start"
            sx={{
              backgroundColor: theme.palette.background.paper,
              borderRadius: 1,
            }}
          >
            <img
              src={el.preview}
              alt={el.message}
              style={{ maxHeight: 210, borderRadius: "10px" }}
            />
            <Stack spacing={2}>
              <Typography variant="subtitle2">Creating Chat App</Typography>
              <Typography
                variant="subtitle2"
                sx={{ color: theme.palette.primary.main }}
                component={Link}
                to="//https://www.youtube.com"
              >
                www.youtube.com
              </Typography>
            </Stack>
            <Typography
              variant="body2"
              color={el.incoming ? theme.palette.text : "#fff"}
            >
              {el.message}
            </Typography>
          </Stack>
        </Stack>
      </Box>
      {menu && (
        <MessageOptions messageDetails={el} handleMessage={handleMessage} userId={userId} />
      )}
    </Stack>
  );
};

const ReplyMsg = ({ el, menu, handleMessage, userId }) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      justifyContent={el.reciver_id === userId ? "end" : "start"}
    >
      <Box
        p={1.5}
        sx={{
          backgroundColor:
            el.sender_id === userId
              ? theme.palette.background.default
              : theme.palette.primary.main,
          borderRadius: 1.5,
          width: "max-content",
        }}
      >
        <Stack spacing={2}>
          <Stack
            p={2}
            direction="column"
            spacing={3}
            alignItems="center"
            sx={{
              backgroundColor: theme.palette.background.paper,
              borderRadius: 1,
            }}
          >
            <Typography variant="body2" color={theme.palette.text}>
              {el.message}
            </Typography>
          </Stack>
          <Typography
            variant="body2"
            color={el.sender_id === userId ? theme.palette.text : "#fff"}
          >
            {el.reply}
          </Typography>
        </Stack>
      </Box>
      {menu && (
        <MessageOptions messageDetails={el} handleMessage={handleMessage} />
      )}
    </Stack>
  );
};

const MediaMsg = ({ el, menu, handleMessage, userId }) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      justifyContent={el.sender_id === userId ? "start" : "end"}
    >
      {
        el?.parent_chat ? (
          <Box
            p={1.5}
            sx={{
              backgroundColor:
                el.sender_id === userId
                  ? theme.palette.background.default
                  : theme.palette.primary.main,
              borderRadius: 1.5,
              width: "max-content",
            }}
          >
            <Stack spacing={2}>
              <Stack
                p={1}
                direction="column"
                spacing={1}
                alignItems="center"
                sx={{
                  backgroundColor:
                    el.sender_id === userId
                      ? theme.palette.primary.main
                      : theme.palette.background.default,
                  borderRadius: 1,
                }}
              >
                {
                  el?.parent_chat?.message_type == 'image' ?
                    (
                      <ImageReply el={el.parent_chat} userId={userId} />
                    )
                    :
                    el?.parent_chat?.message_type == 'file' ?
                      (
                        <DocReply el={el.parent_chat} userId={userId} sender_id={el.sender_id} />
                      )
                      :
                      (
                        <Typography variant="body2" color={theme.palette.text}>
                          {el?.parent_chat?.message}
                        </Typography>
                      )
                }
              </Stack>
              <Stack spacing={1} sx={{ position: 'relative' }}>
                <img
                  src={el.attechment_url}
                  alt={el.message}
                  style={{ maxHeight: 210, borderRadius: "10px" }}
                />
                <Typography
                  variant="body2"
                  color={el.sender_id === userId ? theme.palette.text : "#fff"}
                >
                  {el.message}
                </Typography>
                <Typography sx={{ fontSize: '10px', textAlign: 'right', marginTop: '5px' }} color={el.sender_id === userId ? theme.palette.text : "#fff"}>
                  {moment(el?.created_at).format('hh:mm A, MMM DD, YYYY')}
                </Typography>
                <IconButton style={{ position: 'absolute', left: '40%', top: '20%', background: 'gray' }}>
                  <a href={el.attechment_url} target="_blank" download><DownloadIcon /></a>
                  {/* <DownloadSimple onClick={() => handleDownload(el)} /> */}
                </IconButton>
              </Stack>
            </Stack>
          </Box>
        )
          :
          (
            <Box
              p={1.5}
              sx={{
                backgroundColor: el.sender_id == userId
                  ? theme.palette.background.default
                  : theme.palette.primary.main,
                borderRadius: 1.5,
                width: "max-content",
              }}
            >
              <Stack spacing={1} sx={{ position: 'relative' }}>
                <img
                  src={el.attechment_url}
                  alt={el.message}
                  style={{ maxHeight: 210, borderRadius: "10px" }}
                />
                <Typography
                  variant="body2"
                  color={el.sender_id === userId ? theme.palette.text : "#fff"}
                >
                  {el.message}
                </Typography>
                <Typography sx={{ fontSize: '10px', textAlign: 'right', marginTop: '5px' }} color={el.sender_id === userId ? theme.palette.text : "#fff"}>
                  {moment(el?.created_at).format('hh:mm A, MMM DD, YYYY')}
                </Typography>
                <IconButton style={{ position: 'absolute', left: '40%', top: '20%', background: 'gray' }}>
                  <a href={el.attechment_url} target="_blank" download><DownloadIcon /></a>
                  {/* <DownloadSimple onClick={() => handleDownload(el)} /> */}
                </IconButton>
              </Stack>
            </Box>
          )
      }

      {
        menu && (
          <MessageOptions messageDetails={el} handleMessage={handleMessage} userId={userId} />
        )
      }
    </Stack >
  );
};

const TextMsg = ({ el, menu, handleMessage, userId }) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      justifyContent={el.sender_id === userId ? "start" : "end"}
    >
      {
        el?.parent_chat ? (
          <Box
            p={1.5}
            sx={{
              backgroundColor:
                el.sender_id === userId
                  ? theme.palette.background.default
                  : theme.palette.primary.main,
              borderRadius: 1.5,
              width: "max-content",
            }}
          >
            <Stack spacing={0}>
              <Stack
                p={2}
                direction="column"
                spacing={3}
                alignItems="center"
                sx={{
                  backgroundColor:
                    el.sender_id === userId
                      ? theme.palette.primary.main
                      : theme.palette.background.default,
                  borderRadius: 1,
                }}
              >
                {
                  el?.parent_chat?.message_type == 'image' ?
                    (
                      <ImageReply el={el.parent_chat} userId={userId} sender_id={el.sender_id} />
                    )
                    :
                    el?.parent_chat?.message_type == 'file' ?
                      (
                        <DocReply el={el.parent_chat} userId={userId} sender_id={el.sender_id} />
                      )
                      :
                      (
                        <Typography variant="body2" color={theme.palette.text}>
                          {el?.parent_chat?.message}
                        </Typography>
                      )
                }
              </Stack>
              <Typography
                sx={{ marginTop: '16px' }}
                variant="body2"
                color={el.sender_id === userId ? theme.palette.text : "#fff"}
              >
                {el?.message}
              </Typography>
              <Typography sx={{ fontSize: '10px', textAlign: 'right', marginTop: '5px' }} color={el.sender_id === userId ? theme.palette.text : "#fff"}>
                {moment(el?.created_at).format('hh:mm A, MMM DD, YYYY')}
              </Typography>
            </Stack>
          </Box>
        )
          :
          (
            <Box
              p={1.5}
              sx={{
                backgroundColor:
                  el.sender_id === userId
                    ? theme.palette.background.default
                    : theme.palette.primary.main,
                borderRadius: 1.5,
                width: "max-content",
              }}
            >
              <Typography
                variant="body2"
                color={el.sender_id === userId ? theme.palette.text : "#fff"}
              >
                {el.message}
              </Typography>
              <Typography sx={{ fontSize: '10px', textAlign: 'right', marginTop: '5px' }} color={el.sender_id === userId ? theme.palette.text : "#fff"}>
                {moment(el?.created_at).format('hh:mm A, MMM DD, YYYY')}
              </Typography>
            </Box>
          )
      }
      {menu && (
        <MessageOptions messageDetails={el} handleMessage={handleMessage} userId={userId} />
      )}
    </Stack>
  );
};

const TimeLine = ({ el }) => {
  const theme = useTheme();
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Divider width="46%" />
      <Typography variant="caption" sx={{ color: theme.palette.text }}>
        {el.text}
      </Typography>
      <Divider width="46%" />
    </Stack>
  );
};

const MessageOptions = ({ messageDetails, handleMessage, userId }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <DotsThreeVertical
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        size={20}
      />

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Stack spacing={1} px={1}>
          {/* {Message_options.map((el) => ( */}
          <MenuItem
            onClick={(e) => {
              handleClose();
              handleMessage(messageDetails, 'Reply');
            }}
          >
            Reply
          </MenuItem>
          {
            messageDetails?.reciver_id === userId && (
              <>
                {
                  messageDetails?.message_type == 'text' && (
                    <MenuItem
                      onClick={(e) => {
                        handleClose();
                        handleMessage(messageDetails, 'Edit');
                      }}
                    >
                      Edit
                    </MenuItem>
                  )
                }
                <MenuItem
                  onClick={(e) => {
                    handleClose();
                    handleMessage(messageDetails, 'Delete Message');
                  }}
                >
                  Delete Message
                </MenuItem>
              </>

            )
          }
          {/* ))} */}
        </Stack>
      </Menu>
    </>
  );
};

const ImageReply = ({ el, userId, sender_id }) => {
  const theme = useTheme();
  return (
    <Stack spacing={0}>
      <img
        src={el.attechment_url}
        alt={el.message}
        style={{ height: 100, width: 100, borderRadius: "10px" }}
      />
      <Typography
        variant="body2"
        color={el.sender_id !== userId ? theme.palette.text : "#fff"}
      >
        {el.message}
      </Typography>
    </Stack>
  )
}

const DocReply = ({ el, userId, sender_id }) => {
  const theme = useTheme();
  return (
    <Stack spacing={0}>
      <img src={
        el?.attachment_name.split('.').pop().toLowerCase() == 'pdf' ?
          pdfImage
          :
          el?.attachment_name.split('.').pop().toLowerCase() == 'txt' ?
            txtImage :
            el?.attachment_name.split('.').pop().toLowerCase() == 'xls' && el?.attachment_name.split('.').pop().toLowerCase() == 'xlsm' && el?.attachment_name.split('.').pop().toLowerCase() == 'xlsx' && el?.attachment_name.split('.').pop().toLowerCase() == 'csv' ?
              xlsImage : docImage
      } alt="" style={{ width: '50px', height: '50px', borderRadius: '10px', marginLeft: '10px' }} />
      <Typography
        variant="body2"
        color={el.sender_id !== userId ? theme.palette.text : "#fff"}
      >
        {el.message}
      </Typography>
    </Stack>
  )
}

// should not be default export, because we need to export multiple things
export { TimeLine, TextMsg, MediaMsg, ReplyMsg, LinkMsg, DocMsg };
