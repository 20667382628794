import { InterfaceContextAPI } from "@contexts/interfaceContext";
import App from "App";
import React from "react";
import "@fontsource/rubik/300.css";
import "@fontsource/rubik/400.css";
import "@fontsource/rubik/500.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fonts/icomoon/icomoon.woff";

// fonts

const OldRoute = () => {
  return (
    <>
      <InterfaceContextAPI>
        <App />
      </InterfaceContextAPI>
    </>
  );
};
export default OldRoute;
