import { Box, Stack } from "@mui/material";
import UserList from "@widgets/Chat/userList";
import Conversation from "@components/Conversation";
import { useState, useEffect } from "react";
import socketIO from "socket.io-client";
import { useSelector } from "react-redux";
export const connectSocket = socketIO.connect(
  process.env.REACT_SOCKET_URL || "https://hrp-preview.com:4000/"
);

const Chats = () => {
  const [selectedUser, setSelectedUser] = useState("");
  const [userName, setUserName] = useState("");
  const { user } = useSelector((state) => state.user);
  const [socket, setSocket] = useState(connectSocket);
  const [toggle, setToggle] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      console.log("socket", socket, socket?.id);
      if (socket?.id) {
        socket.emit("user_online", {
          login_id: user.id,
          socket_id: socket.id,
        });
        socket.on("online_users_list", (data) => {
          console.log("data", data);
        });
      }
    }, 200);
  }, [socket]);
  return (
    <Stack
      direction="row"
      sx={{
        width: "100%",
        marginTop: "10px",
        maxHeight: "calc(100vh - 130px)",
        paddingTop: {
          xs: "72px",
          sm: "130px",
          md: "130px",
          lg: "0",
        },
      }}
    >
      <UserList
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        setUserName={setUserName}
        toggle={toggle}
        setToggle={setToggle}
        socket={socket}
        loginId={user.id}
      />
      <Box
        sx={{
          // height: "100%",
          width: {
            xs: "100%",
            lg: "100%",
            // lg: "calc(100vw - 420px)",
          },
          display: {
            xs: toggle ? "none" : "block",
            md: "block",
          },
          backgroundColor: "#F0F4FA",
        }}
        minHeight="calc(100vh - 130px)"
      >
        <Conversation
          userId={selectedUser}
          userName={userName}
          socket={socket}
          setToggle={setToggle}
          toggle={toggle}
          setSelectedUser={setSelectedUser}
        />
      </Box>
    </Stack>
  );
};

export default Chats;
