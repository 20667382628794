// import Checkout from "@pages/Checkout";
import AddReferrals from "../pages/addReferrals";
// import Dashboard from "../pages/dashboard";
import ForgotPassword from "../pages/forgotPassword";
import Login from "../pages/login";
import Register from "../pages/register";
import ResetPassword from "../pages/resetPassword";
import Subscription from "../pages/subscription";
import Verify from "../pages/verify";
import { StaticPageLayout } from "frontend/pages/static/static-page";
import {
  clinics,
  homeHealthCareAgencies,
  hospiceAgencies,
  hospitals,
  howItWorks,
  longTermCareFacility,
  walkInClinics,
  whoWeAre,
} from "frontend/pages/static/content";
import Contact from "frontend/pages/contact";
import OPT from "frontend/pages/opt";

export const routes = [
  {
    title: "Login",
    path: "/login",
    element: <Login />,
    isProtected: false,
  },
  {
    title: "OPT",
    path: "/opt",
    element: <OPT />,
    isProtected: false,
  },
  {
    title: "Register",
    path: "/register",
    element: <Register />,
    isProtected: false,
  },
  {
    title: "Forgot Password",
    path: "/forgot-password",
    element: <ForgotPassword />,
    isProtected: false,
  },
  {
    title: "Verify",
    path: "/verify/:token",
    element: <Verify />,
    isProtected: false,
  },
  {
    title: "Subscription",
    path: "/subscription",
    element: <Subscription />,
    isProtected: true,
  },
  {
    title: "AddReferrals",
    path: "/add-referrals",
    element: <AddReferrals />,
    isProtected: true,
  },
  {
    title: "ResetPassword",
    path: "/reset-password/:token",
    element: <ResetPassword />,
    isProtected: false,
  },
  {
    title: "Contact Us",
    path: "/contact",
    element: <Contact />,
    isProtected: false,
  },  
  {
    title: "Who We Are",
    path: "/who-we-are",
    element: <StaticPageLayout props={whoWeAre} />,
    isProtected: false,
  },
  {
    title: "How It Works",
    path: "/how-it-works",
    element: <StaticPageLayout props={howItWorks} />,
    isProtected: false,
  },
  {
    title: "Hospital",
    path: "/hospitals",
    element: <StaticPageLayout props={hospitals} />,
    isProtected: false,
  },
  {
    title: "Clinics",
    path: "/clinics",
    element: <StaticPageLayout props={clinics} />,
    isProtected: false,
  },
  {
    title: "Long Term Care Facilities",
    path: "/long-term-care-facilities",
    element: <StaticPageLayout props={longTermCareFacility} />,
    isProtected: false,
  },
  {
    title: "Home Healthcare Agencies",
    path: "/home-healthcare-agencies",
    element: <StaticPageLayout props={homeHealthCareAgencies} />,
    isProtected: false,
  },
  {
    title: "Hospice Agencies",
    path: "/hospice-agencies",
    element: <StaticPageLayout props={hospiceAgencies} />,
    isProtected: false,
  },
  {
    title: "Walk In Clinics",
    path: "/walk-in-clinics",
    element: <StaticPageLayout props={walkInClinics} />,
    isProtected: false,
  },
  {
    title: "Walk In Clinics",
    path: "/walk-in-clinics",
    element: <StaticPageLayout props={walkInClinics} />,
    isProtected: false,
  },
];
