export const defaultCountryCode="233"

export const healthCareType = [
    {
      name: "Hospital",
      value: "Hospital",
    },
    {
      name: "Long Term Care Facility",
      value: "LongTermCare",
    },
    {
      name: "Hospice Agency",
      value: "HospiceAgency",
    },
    {
      name: "Home Healthcare",
      value: "HomeHealthcare",
    },
    {
      name: "Clinic",
      value: "Clinic",
    },
    {
      name: "Walk in Clinic",
      value: "WalkInClinic",
    },
  ];
  
  export const countryList = [
    {
      flag: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_the_Taliban.svg",
      country: "Afghanistan",
      code: "93",
    },
    {
      flag: "https://flagcdn.com/ax.svg",
      country: "Åland Islands",
      code: "358",
    },
    {
      flag: "https://flagcdn.com/al.svg",
      country: "Albania",
      code: "355",
    },
    {
      flag: "https://flagcdn.com/dz.svg",
      country: "Algeria",
      code: "213",
    },
    {
      flag: "https://flagcdn.com/as.svg",
      country: "American Samoa",
      code: "1",
    },
    {
      flag: "https://flagcdn.com/ad.svg",
      country: "Andorra",
      code: "376",
    },
    {
      flag: "https://flagcdn.com/ao.svg",
      country: "Angola",
      code: "244",
    },
    {
      flag: "https://flagcdn.com/ai.svg",
      country: "Anguilla",
      code: "1",
    },
    {
      flag: "https://flagcdn.com/aq.svg",
      country: "Antarctica",
      code: "672",
    },
    {
      flag: "https://flagcdn.com/ag.svg",
      country: "Antigua and Barbuda",
      code: "1",
    },
    {
      flag: "https://flagcdn.com/ar.svg",
      country: "Argentina",
      code: "54",
    },
    {
      flag: "https://flagcdn.com/am.svg",
      country: "Armenia",
      code: "374",
    },
    {
      flag: "https://flagcdn.com/aw.svg",
      country: "Aruba",
      code: "297",
    },
    {
      flag: "https://flagcdn.com/au.svg",
      country: "Australia",
      code: "61",
    },
    {
      flag: "https://flagcdn.com/at.svg",
      country: "Austria",
      code: "43",
    },
    {
      flag: "https://flagcdn.com/az.svg",
      country: "Azerbaijan",
      code: "994",
    },
    {
      flag: "https://flagcdn.com/bs.svg",
      country: "Bahamas",
      code: "1",
    },
    {
      flag: "https://flagcdn.com/bh.svg",
      country: "Bahrain",
      code: "973",
    },
    {
      flag: "https://flagcdn.com/bd.svg",
      country: "Bangladesh",
      code: "880",
    },
    {
      flag: "https://flagcdn.com/bb.svg",
      country: "Barbados",
      code: "1",
    },
    {
      flag: "https://flagcdn.com/by.svg",
      country: "Belarus",
      code: "375",
    },
    {
      flag: "https://flagcdn.com/be.svg",
      country: "Belgium",
      code: "32",
    },
    {
      flag: "https://flagcdn.com/bz.svg",
      country: "Belize",
      code: "501",
    },
    {
      flag: "https://flagcdn.com/bj.svg",
      country: "Benin",
      code: "229",
    },
    {
      flag: "https://flagcdn.com/bm.svg",
      country: "Bermuda",
      code: "1",
    },
    {
      flag: "https://flagcdn.com/bt.svg",
      country: "Bhutan",
      code: "975",
    },
    {
      flag: "https://flagcdn.com/bo.svg",
      country: "Bolivia (Plurinational State of)",
      code: "591",
    },
    {
      flag: "https://flagcdn.com/bq.svg",
      country: "Bonaire, Sint Eustatius and Saba",
      code: "599",
    },
    {
      flag: "https://flagcdn.com/ba.svg",
      country: "Bosnia and Herzegovina",
      code: "387",
    },
    {
      flag: "https://flagcdn.com/bw.svg",
      country: "Botswana",
      code: "267",
    },
    {
      flag: "https://flagcdn.com/bv.svg",
      country: "Bouvet Island",
      code: "47",
    },
    {
      flag: "https://flagcdn.com/br.svg",
      country: "Brazil",
      code: "55",
    },
    {
      flag: "https://flagcdn.com/io.svg",
      country: "British Indian Ocean Territory",
      code: "246",
    },
    {
      flag: "https://flagcdn.com/um.svg",
      country: "United States Minor Outlying Islands",
      code: "246",
    },
    {
      flag: "https://flagcdn.com/vg.svg",
      country: "Virgin Islands (British)",
      code: "1",
    },
    {
      flag: "https://flagcdn.com/vi.svg",
      country: "Virgin Islands (U.S.)",
      code: "1 340",
    },
    {
      flag: "https://flagcdn.com/bn.svg",
      country: "Brunei Darussalam",
      code: "673",
    },
    {
      flag: "https://flagcdn.com/bg.svg",
      country: "Bulgaria",
      code: "359",
    },
    {
      flag: "https://flagcdn.com/bf.svg",
      country: "Burkina Faso",
      code: "226",
    },
    {
      flag: "https://flagcdn.com/bi.svg",
      country: "Burundi",
      code: "257",
    },
    {
      flag: "https://flagcdn.com/kh.svg",
      country: "Cambodia",
      code: "855",
    },
    {
      flag: "https://flagcdn.com/cm.svg",
      country: "Cameroon",
      code: "237",
    },
    {
      flag: "https://flagcdn.com/ca.svg",
      country: "Canada",
      code: "1",
    },
    {
      flag: "https://flagcdn.com/cv.svg",
      country: "Cabo Verde",
      code: "238",
    },
    {
      flag: "https://flagcdn.com/ky.svg",
      country: "Cayman Islands",
      code: "1",
    },
    {
      flag: "https://flagcdn.com/cf.svg",
      country: "Central African Republic",
      code: "236",
    },
    {
      flag: "https://flagcdn.com/td.svg",
      country: "Chad",
      code: "235",
    },
    {
      flag: "https://flagcdn.com/cl.svg",
      country: "Chile",
      code: "56",
    },
    {
      flag: "https://flagcdn.com/cn.svg",
      country: "China",
      code: "86",
    },
    {
      flag: "https://flagcdn.com/cx.svg",
      country: "Christmas Island",
      code: "61",
    },
    {
      flag: "https://flagcdn.com/cc.svg",
      country: "Cocos (Keeling) Islands",
      code: "61",
    },
    {
      flag: "https://flagcdn.com/co.svg",
      country: "Colombia",
      code: "57",
    },
    {
      flag: "https://flagcdn.com/km.svg",
      country: "Comoros",
      code: "269",
    },
    {
      flag: "https://flagcdn.com/cg.svg",
      country: "Congo",
      code: "242",
    },
    {
      flag: "https://flagcdn.com/cd.svg",
      country: "Congo (Democratic Republic of the)",
      code: "243",
    },
    {
      flag: "https://flagcdn.com/ck.svg",
      country: "Cook Islands",
      code: "682",
    },
    {
      flag: "https://flagcdn.com/cr.svg",
      country: "Costa Rica",
      code: "506",
    },
    {
      flag: "https://flagcdn.com/hr.svg",
      country: "Croatia",
      code: "385",
    },
    {
      flag: "https://flagcdn.com/cu.svg",
      country: "Cuba",
      code: "53",
    },
    {
      flag: "https://flagcdn.com/cw.svg",
      country: "Curaçao",
      code: "599",
    },
    {
      flag: "https://flagcdn.com/cy.svg",
      country: "Cyprus",
      code: "357",
    },
    {
      flag: "https://flagcdn.com/cz.svg",
      country: "Czech Republic",
      code: "420",
    },
    {
      flag: "https://flagcdn.com/dk.svg",
      country: "Denmark",
      code: "45",
    },
    {
      flag: "https://flagcdn.com/dj.svg",
      country: "Djibouti",
      code: "253",
    },
    {
      flag: "https://flagcdn.com/dm.svg",
      country: "Dominica",
      code: "1",
    },
    {
      flag: "https://flagcdn.com/do.svg",
      country: "Dominican Republic",
      code: "1",
    },
    {
      flag: "https://flagcdn.com/ec.svg",
      country: "Ecuador",
      code: "593",
    },
    {
      flag: "https://flagcdn.com/eg.svg",
      country: "Egypt",
      code: "20",
    },
    {
      flag: "https://flagcdn.com/sv.svg",
      country: "El Salvador",
      code: "503",
    },
    {
      flag: "https://flagcdn.com/gq.svg",
      country: "Equatorial Guinea",
      code: "240",
    },
    {
      flag: "https://flagcdn.com/er.svg",
      country: "Eritrea",
      code: "291",
    },
    {
      flag: "https://flagcdn.com/ee.svg",
      country: "Estonia",
      code: "372",
    },
    {
      flag: "https://flagcdn.com/et.svg",
      country: "Ethiopia",
      code: "251",
    },
    {
      flag: "https://flagcdn.com/fk.svg",
      country: "Falkland Islands (Malvinas)",
      code: "500",
    },
    {
      flag: "https://flagcdn.com/fo.svg",
      country: "Faroe Islands",
      code: "298",
    },
    {
      flag: "https://flagcdn.com/fj.svg",
      country: "Fiji",
      code: "679",
    },
    {
      flag: "https://flagcdn.com/fi.svg",
      country: "Finland",
      code: "358",
    },
    {
      flag: "https://flagcdn.com/fr.svg",
      country: "France",
      code: "33",
    },
    {
      flag: "https://flagcdn.com/gf.svg",
      country: "French Guiana",
      code: "594",
    },
    {
      flag: "https://flagcdn.com/pf.svg",
      country: "French Polynesia",
      code: "689",
    },
    {
      flag: "https://flagcdn.com/tf.svg",
      country: "French Southern Territories",
      code: "262",
    },
    {
      flag: "https://flagcdn.com/ga.svg",
      country: "Gabon",
      code: "241",
    },
    {
      flag: "https://flagcdn.com/gm.svg",
      country: "Gambia",
      code: "220",
    },
    {
      flag: "https://flagcdn.com/ge.svg",
      country: "Georgia",
      code: "995",
    },
    {
      flag: "https://flagcdn.com/de.svg",
      country: "Germany",
      code: "49",
    },
    {
      flag: "https://flagcdn.com/gh.svg",
      country: "Ghana",
      code: "233",
    },
    {
      flag: "https://flagcdn.com/gi.svg",
      country: "Gibraltar",
      code: "350",
    },
    {
      flag: "https://flagcdn.com/gr.svg",
      country: "Greece",
      code: "30",
    },
    {
      flag: "https://flagcdn.com/gl.svg",
      country: "Greenland",
      code: "299",
    },
    {
      flag: "https://flagcdn.com/gd.svg",
      country: "Grenada",
      code: "1",
    },
    {
      flag: "https://flagcdn.com/gp.svg",
      country: "Guadeloupe",
      code: "590",
    },
    {
      flag: "https://flagcdn.com/gu.svg",
      country: "Guam",
      code: "1",
    },
    {
      flag: "https://flagcdn.com/gt.svg",
      country: "Guatemala",
      code: "502",
    },
    {
      flag: "https://flagcdn.com/gg.svg",
      country: "Guernsey",
      code: "44",
    },
    {
      flag: "https://flagcdn.com/gn.svg",
      country: "Guinea",
      code: "224",
    },
    {
      flag: "https://flagcdn.com/gw.svg",
      country: "Guinea-Bissau",
      code: "245",
    },
    {
      flag: "https://flagcdn.com/gy.svg",
      country: "Guyana",
      code: "592",
    },
    {
      flag: "https://flagcdn.com/ht.svg",
      country: "Haiti",
      code: "509",
    },
    {
      flag: "https://flagcdn.com/hm.svg",
      country: "Heard Island and McDonald Islands",
      code: "672",
    },
    {
      flag: "https://flagcdn.com/va.svg",
      country: "Vatican City",
      code: "379",
    },
    {
      flag: "https://flagcdn.com/hn.svg",
      country: "Honduras",
      code: "504",
    },
    {
      flag: "https://flagcdn.com/hu.svg",
      country: "Hungary",
      code: "36",
    },
    {
      flag: "https://flagcdn.com/hk.svg",
      country: "Hong Kong",
      code: "852",
    },
    {
      flag: "https://flagcdn.com/is.svg",
      country: "Iceland",
      code: "354",
    },
    {
      flag: "https://flagcdn.com/in.svg",
      country: "India",
      code: "91",
    },
    {
      flag: "https://flagcdn.com/id.svg",
      country: "Indonesia",
      code: "62",
    },
    {
      flag: "https://flagcdn.com/ci.svg",
      country: "Ivory Coast",
      code: "225",
    },
    {
      flag: "https://flagcdn.com/ir.svg",
      country: "Iran (Islamic Republic of)",
      code: "98",
    },
    {
      flag: "https://flagcdn.com/iq.svg",
      country: "Iraq",
      code: "964",
    },
    {
      flag: "https://flagcdn.com/ie.svg",
      country: "Ireland",
      code: "353",
    },
    {
      flag: "https://flagcdn.com/im.svg",
      country: "Isle of Man",
      code: "44",
    },
    {
      flag: "https://flagcdn.com/il.svg",
      country: "Israel",
      code: "972",
    },
    {
      flag: "https://flagcdn.com/it.svg",
      country: "Italy",
      code: "39",
    },
    {
      flag: "https://flagcdn.com/jm.svg",
      country: "Jamaica",
      code: "1",
    },
    {
      flag: "https://flagcdn.com/jp.svg",
      country: "Japan",
      code: "81",
    },
    {
      flag: "https://flagcdn.com/je.svg",
      country: "Jersey",
      code: "44",
    },
    {
      flag: "https://flagcdn.com/jo.svg",
      country: "Jordan",
      code: "962",
    },
    {
      flag: "https://flagcdn.com/kz.svg",
      country: "Kazakhstan",
      code: "76",
    },
    {
      flag: "https://flagcdn.com/ke.svg",
      country: "Kenya",
      code: "254",
    },
    {
      flag: "https://flagcdn.com/ki.svg",
      country: "Kiribati",
      code: "686",
    },
    {
      flag: "https://flagcdn.com/kw.svg",
      country: "Kuwait",
      code: "965",
    },
    {
      flag: "https://flagcdn.com/kg.svg",
      country: "Kyrgyzstan",
      code: "996",
    },
    {
      flag: "https://flagcdn.com/la.svg",
      country: "Lao People's Democratic Republic",
      code: "856",
    },
    {
      flag: "https://flagcdn.com/lv.svg",
      country: "Latvia",
      code: "371",
    },
    {
      flag: "https://flagcdn.com/lb.svg",
      country: "Lebanon",
      code: "961",
    },
    {
      flag: "https://flagcdn.com/ls.svg",
      country: "Lesotho",
      code: "266",
    },
    {
      flag: "https://flagcdn.com/lr.svg",
      country: "Liberia",
      code: "231",
    },
    {
      flag: "https://flagcdn.com/ly.svg",
      country: "Libya",
      code: "218",
    },
    {
      flag: "https://flagcdn.com/li.svg",
      country: "Liechtenstein",
      code: "423",
    },
    {
      flag: "https://flagcdn.com/lt.svg",
      country: "Lithuania",
      code: "370",
    },
    {
      flag: "https://flagcdn.com/lu.svg",
      country: "Luxembourg",
      code: "352",
    },
    {
      flag: "https://flagcdn.com/mo.svg",
      country: "Macao",
      code: "853",
    },
    {
      flag: "https://flagcdn.com/mk.svg",
      country: "North Macedonia",
      code: "389",
    },
    {
      flag: "https://flagcdn.com/mg.svg",
      country: "Madagascar",
      code: "261",
    },
    {
      flag: "https://flagcdn.com/mw.svg",
      country: "Malawi",
      code: "265",
    },
    {
      flag: "https://flagcdn.com/my.svg",
      country: "Malaysia",
      code: "60",
    },
    {
      flag: "https://flagcdn.com/mv.svg",
      country: "Maldives",
      code: "960",
    },
    {
      flag: "https://flagcdn.com/ml.svg",
      country: "Mali",
      code: "223",
    },
    {
      flag: "https://flagcdn.com/mt.svg",
      country: "Malta",
      code: "356",
    },
    {
      flag: "https://flagcdn.com/mh.svg",
      country: "Marshall Islands",
      code: "692",
    },
    {
      flag: "https://flagcdn.com/mq.svg",
      country: "Martinique",
      code: "596",
    },
    {
      flag: "https://flagcdn.com/mr.svg",
      country: "Mauritania",
      code: "222",
    },
    {
      flag: "https://flagcdn.com/mu.svg",
      country: "Mauritius",
      code: "230",
    },
    {
      flag: "https://flagcdn.com/yt.svg",
      country: "Mayotte",
      code: "262",
    },
    {
      flag: "https://flagcdn.com/mx.svg",
      country: "Mexico",
      code: "52",
    },
    {
      flag: "https://flagcdn.com/fm.svg",
      country: "Micronesia (Federated States of)",
      code: "691",
    },
    {
      flag: "https://flagcdn.com/md.svg",
      country: "Moldova (Republic of)",
      code: "373",
    },
    {
      flag: "https://flagcdn.com/mc.svg",
      country: "Monaco",
      code: "377",
    },
    {
      flag: "https://flagcdn.com/mn.svg",
      country: "Mongolia",
      code: "976",
    },
    {
      flag: "https://flagcdn.com/me.svg",
      country: "Montenegro",
      code: "382",
    },
    {
      flag: "https://flagcdn.com/ms.svg",
      country: "Montserrat",
      code: "1",
    },
    {
      flag: "https://flagcdn.com/ma.svg",
      country: "Morocco",
      code: "212",
    },
    {
      flag: "https://flagcdn.com/mz.svg",
      country: "Mozambique",
      code: "258",
    },
    {
      flag: "https://flagcdn.com/mm.svg",
      country: "Myanmar",
      code: "95",
    },
    {
      flag: "https://flagcdn.com/na.svg",
      country: "Namibia",
      code: "264",
    },
    {
      flag: "https://flagcdn.com/nr.svg",
      country: "Nauru",
      code: "674",
    },
    {
      flag: "https://flagcdn.com/np.svg",
      country: "Nepal",
      code: "977",
    },
    {
      flag: "https://flagcdn.com/nl.svg",
      country: "Netherlands",
      code: "31",
    },
    {
      flag: "https://flagcdn.com/nc.svg",
      country: "New Caledonia",
      code: "687",
    },
    {
      flag: "https://flagcdn.com/nz.svg",
      country: "New Zealand",
      code: "64",
    },
    {
      flag: "https://flagcdn.com/ni.svg",
      country: "Nicaragua",
      code: "505",
    },
    {
      flag: "https://flagcdn.com/ne.svg",
      country: "Niger",
      code: "227",
    },
    {
      flag: "https://flagcdn.com/ng.svg",
      country: "Nigeria",
      code: "234",
    },
    {
      flag: "https://flagcdn.com/nu.svg",
      country: "Niue",
      code: "683",
    },
    {
      flag: "https://flagcdn.com/nf.svg",
      country: "Norfolk Island",
      code: "672",
    },
    {
      flag: "https://flagcdn.com/kp.svg",
      country: "Korea (Democratic People's Republic of)",
      code: "850",
    },
    {
      flag: "https://flagcdn.com/mp.svg",
      country: "Northern Mariana Islands",
      code: "1",
    },
    {
      flag: "https://flagcdn.com/no.svg",
      country: "Norway",
      code: "47",
    },
    {
      flag: "https://flagcdn.com/om.svg",
      country: "Oman",
      code: "968",
    },
    {
      flag: "https://flagcdn.com/pk.svg",
      country: "Pakistan",
      code: "92",
    },
    {
      flag: "https://flagcdn.com/pw.svg",
      country: "Palau",
      code: "680",
    },
    {
      flag: "https://flagcdn.com/ps.svg",
      country: "Palestine, State of",
      code: "970",
    },
    {
      flag: "https://flagcdn.com/pa.svg",
      country: "Panama",
      code: "507",
    },
    {
      flag: "https://flagcdn.com/pg.svg",
      country: "Papua New Guinea",
      code: "675",
    },
    {
      flag: "https://flagcdn.com/py.svg",
      country: "Paraguay",
      code: "595",
    },
    {
      flag: "https://flagcdn.com/pe.svg",
      country: "Peru",
      code: "51",
    },
    {
      flag: "https://flagcdn.com/ph.svg",
      country: "Philippines",
      code: "63",
    },
    {
      flag: "https://flagcdn.com/pn.svg",
      country: "Pitcairn",
      code: "64",
    },
    {
      flag: "https://flagcdn.com/pl.svg",
      country: "Poland",
      code: "48",
    },
    {
      flag: "https://flagcdn.com/pt.svg",
      country: "Portugal",
      code: "351",
    },
    {
      flag: "https://flagcdn.com/pr.svg",
      country: "Puerto Rico",
      code: "1",
    },
    {
      flag: "https://flagcdn.com/qa.svg",
      country: "Qatar",
      code: "974",
    },
    {
      flag: "https://flagcdn.com/xk.svg",
      country: "Republic of Kosovo",
      code: "383",
    },
    {
      flag: "https://flagcdn.com/re.svg",
      country: "Réunion",
      code: "262",
    },
    {
      flag: "https://flagcdn.com/ro.svg",
      country: "Romania",
      code: "40",
    },
    {
      flag: "https://flagcdn.com/ru.svg",
      country: "Russian Federation",
      code: "7",
    },
    {
      flag: "https://flagcdn.com/rw.svg",
      country: "Rwanda",
      code: "250",
    },
    {
      flag: "https://flagcdn.com/bl.svg",
      country: "Saint Barthélemy",
      code: "590",
    },
    {
      flag: "https://flagcdn.com/sh.svg",
      country: "Saint Helena, Ascension and Tristan da Cunha",
      code: "290",
    },
    {
      flag: "https://flagcdn.com/kn.svg",
      country: "Saint Kitts and Nevis",
      code: "1",
    },
    {
      flag: "https://flagcdn.com/lc.svg",
      country: "Saint Lucia",
      code: "1",
    },
    {
      flag: "https://flagcdn.com/mf.svg",
      country: "Saint Martin (French part)",
      code: "590",
    },
    {
      flag: "https://flagcdn.com/pm.svg",
      country: "Saint Pierre and Miquelon",
      code: "508",
    },
    {
      flag: "https://flagcdn.com/vc.svg",
      country: "Saint Vincent and the Grenadines",
      code: "1",
    },
    {
      flag: "https://flagcdn.com/ws.svg",
      country: "Samoa",
      code: "685",
    },
    {
      flag: "https://flagcdn.com/sm.svg",
      country: "San Marino",
      code: "378",
    },
    {
      flag: "https://flagcdn.com/st.svg",
      country: "Sao Tome and Principe",
      code: "239",
    },
    {
      flag: "https://flagcdn.com/sa.svg",
      country: "Saudi Arabia",
      code: "966",
    },
    {
      flag: "https://flagcdn.com/sn.svg",
      country: "Senegal",
      code: "221",
    },
    {
      flag: "https://flagcdn.com/rs.svg",
      country: "Serbia",
      code: "381",
    },
    {
      flag: "https://flagcdn.com/sc.svg",
      country: "Seychelles",
      code: "248",
    },
    {
      flag: "https://flagcdn.com/sl.svg",
      country: "Sierra Leone",
      code: "232",
    },
    {
      flag: "https://flagcdn.com/sg.svg",
      country: "Singapore",
      code: "65",
    },
    {
      flag: "https://flagcdn.com/sx.svg",
      country: "Sint Maarten (Dutch part)",
      code: "1",
    },
    {
      flag: "https://flagcdn.com/sk.svg",
      country: "Slovakia",
      code: "421",
    },
    {
      flag: "https://flagcdn.com/si.svg",
      country: "Slovenia",
      code: "386",
    },
    {
      flag: "https://flagcdn.com/sb.svg",
      country: "Solomon Islands",
      code: "677",
    },
    {
      flag: "https://flagcdn.com/so.svg",
      country: "Somalia",
      code: "252",
    },
    {
      flag: "https://flagcdn.com/za.svg",
      country: "South Africa",
      code: "27",
    },
    {
      flag: "https://flagcdn.com/gs.svg",
      country: "South Georgia and the South Sandwich Islands",
      code: "500",
    },
    {
      flag: "https://flagcdn.com/kr.svg",
      country: "Korea (Republic of)",
      code: "82",
    },
    {
      flag: "https://flagcdn.com/es.svg",
      country: "Spain",
      code: "34",
    },
    {
      flag: "https://flagcdn.com/lk.svg",
      country: "Sri Lanka",
      code: "94",
    },
    {
      flag: "https://flagcdn.com/sd.svg",
      country: "Sudan",
      code: "249",
    },
    {
      flag: "https://flagcdn.com/ss.svg",
      country: "South Sudan",
      code: "211",
    },
    {
      flag: "https://flagcdn.com/sr.svg",
      country: "Suriname",
      code: "597",
    },
    {
      flag: "https://flagcdn.com/sj.svg",
      country: "Svalbard and Jan Mayen",
      code: "47",
    },
    {
      flag: "https://flagcdn.com/sz.svg",
      country: "Swaziland",
      code: "268",
    },
    {
      flag: "https://flagcdn.com/se.svg",
      country: "Sweden",
      code: "46",
    },
    {
      flag: "https://flagcdn.com/ch.svg",
      country: "Switzerland",
      code: "41",
    },
    {
      flag: "https://flagcdn.com/sy.svg",
      country: "Syrian Arab Republic",
      code: "963",
    },
    {
      flag: "https://flagcdn.com/tw.svg",
      country: "Taiwan",
      code: "886",
    },
    {
      flag: "https://flagcdn.com/tj.svg",
      country: "Tajikistan",
      code: "992",
    },
    {
      flag: "https://flagcdn.com/tz.svg",
      country: "Tanzania, United Republic of",
      code: "255",
    },
    {
      flag: "https://flagcdn.com/th.svg",
      country: "Thailand",
      code: "66",
    },
    {
      flag: "https://flagcdn.com/tl.svg",
      country: "Timor-Leste",
      code: "670",
    },
    {
      flag: "https://flagcdn.com/tg.svg",
      country: "Togo",
      code: "228",
    },
    {
      flag: "https://flagcdn.com/tk.svg",
      country: "Tokelau",
      code: "690",
    },
    {
      flag: "https://flagcdn.com/to.svg",
      country: "Tonga",
      code: "676",
    },
    {
      flag: "https://flagcdn.com/tt.svg",
      country: "Trinidad and Tobago",
      code: "1",
    },
    {
      flag: "https://flagcdn.com/tn.svg",
      country: "Tunisia",
      code: "216",
    },
    {
      flag: "https://flagcdn.com/tr.svg",
      country: "Turkey",
      code: "90",
    },
    {
      flag: "https://flagcdn.com/tm.svg",
      country: "Turkmenistan",
      code: "993",
    },
    {
      flag: "https://flagcdn.com/tc.svg",
      country: "Turks and Caicos Islands",
      code: "1",
    },
    {
      flag: "https://flagcdn.com/tv.svg",
      country: "Tuvalu",
      code: "688",
    },
    {
      flag: "https://flagcdn.com/ug.svg",
      country: "Uganda",
      code: "256",
    },
    {
      flag: "https://flagcdn.com/ua.svg",
      country: "Ukraine",
      code: "380",
    },
    {
      flag: "https://flagcdn.com/ae.svg",
      country: "United Arab Emirates",
      code: "971",
    },
    {
      flag: "https://flagcdn.com/gb.svg",
      country: "United Kingdom of Great Britain and Northern Ireland",
      code: "44",
    },
    {
      flag: "https://flagcdn.com/us.svg",
      country: "United States of America",
      code: "1",
    },
    {
      flag: "https://flagcdn.com/uy.svg",
      country: "Uruguay",
      code: "598",
    },
    {
      flag: "https://flagcdn.com/uz.svg",
      country: "Uzbekistan",
      code: "998",
    },
    {
      flag: "https://flagcdn.com/vu.svg",
      country: "Vanuatu",
      code: "678",
    },
    {
      flag: "https://flagcdn.com/ve.svg",
      country: "Venezuela (Bolivarian Republic of)",
      code: "58",
    },
    {
      flag: "https://flagcdn.com/vn.svg",
      country: "Vietnam",
      code: "84",
    },
    {
      flag: "https://flagcdn.com/wf.svg",
      country: "Wallis and Futuna",
      code: "681",
    },
    {
      flag: "https://flagcdn.com/eh.svg",
      country: "Western Sahara",
      code: "212",
    },
    {
      flag: "https://flagcdn.com/ye.svg",
      country: "Yemen",
      code: "967",
    },
    {
      flag: "https://flagcdn.com/zm.svg",
      country: "Zambia",
      code: "260",
    },
    {
      flag: "https://flagcdn.com/zw.svg",
      country: "Zimbabwe",
      code: "263",
    },
  ];
  