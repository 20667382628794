import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { routes } from "./routesData";
import Home from "../pages/home";
import ProtectedRoute from "./protectedRouting";
import { Logout } from "helper/logout";
import Header from "component/layout/header";
import Footer from "component/layout/footer";
import FileNotFound from "frontend/pages/fileNotFound";
import ErrorPageRoot from "frontend/pages/errorPage";
import Preloader from "frontend/pages/preloader";
import { Helmet } from "react-helmet";
import Signature from "frontend/pages/signature";

const RouteConfig = () => {
  const location = useLocation().pathname;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);

    // Clean up the timer
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <Helmet>
        <link
          rel="preload"
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"
          as="style"
        />
        <script
          rel="preload"
          src="https://cdn.tailwindcss.com"
          as="script"
        ></script>
      </Helmet>
      {
        loading && !location.includes("portal") ? (
          <Preloader />
        )
          :
          (
            <>
              {(!location.includes("portal") && !location.includes("e-sign")) && <Header />}
              <Routes>
                <Route index path="/" element={<Home />} />
                {routes?.map((item) =>
                  item.isProtected ? (
                    <Route
                      key={item.path}
                      path={item.path}
                      element={<ProtectedRoute>{item.element}</ProtectedRoute>}
                    />
                  ) : (
                    <Route key={item.path} path={item.path} element={item.element} />
                  )
                )}
                <Route path="/logout" element={<Logout />} />
                <Route path="*" element={<FileNotFound />} />
                <Route path="/404" element={<ErrorPageRoot />} />
                <Route path="/e-sign/:token" element={<Signature />} />
              </Routes>
              {(!location.includes("portal") && !location.includes("e-sign")) && <Footer />}
            </>
          )
      }

    </>
  );
};

export default RouteConfig;
