import React, { useEffect, useState } from "react";
import { Form, Formik, ErrorMessage, Field } from "formik";
import { useParams, useNavigate } from "react-router-dom";
import { Api } from "../../api/api";
import SweetAlert from 'react-bootstrap-sweetalert';

const ResetPassword = () => {
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const token = useParams().token;
    const [formValue, setFormValue] = useState({
        password: "",
        confirmPassword: "",
    })
    const navigate = useNavigate();

    const validate = (values) => {
        const errors = {};

        if (!values.password) {
            errors.password = "Password is required";
        } else if (values.password.length < 6) {
            errors.password = "Password must be at least 6 characters";
        }

        if (!values.confirmPassword) {
            errors.confirmPassword = "Confirm Password is required";
        } else if (values.confirmPassword !== values.password) {
            errors.confirmPassword = "Passwords do not match";
        }

        return errors;
    }

    const onSubmit = (values, { setSubmitting, resetForm }) => {
        setLoading(true);
        setFormValue(values);
        setSubmitting(false);
        let data = new FormData();
        data.append("password", values.password);
        data.append("token", token);
        Api.ResetPassword(data)
            .then((res) => {
                if (res?.status === 200) {
                    setShowDialog(true);
                    // successToast(res.data.message);
                }
                setLoading(false);
                setFormValue({});
                resetForm();
            })
            .catch((res) => {
                // errorToast(res.response?.data?.message);
                setShowErrorDialog(true);
                setLoading(false);
            });
    };

    const handleClose = () => {
        setShowDialog(false);
        return navigate("/login");
    };

    const handleCloseError = () => {
        setShowErrorDialog(false);
        return navigate("/");
    }

    return (
        <>
            <section>
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto mt-5 mb-5 lg:py-0">
                    <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                                Reset Password
                            </h1>
                            <Formik
                                initialValues={formValue}
                                validate={validate}
                                onSubmit={onSubmit}
                            >
                                <Form className="space-y-4 md:space-y-6">
                                    <div>
                                        <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">Password</label>
                                        <Field
                                            as="input"
                                            type="password"
                                            id="password"
                                            name="password"
                                            autoComplete="off"
                                            placeholder="Password"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                        />
                                        <ErrorMessage name={'password'}>
                                            {(msg) => <div className="text-red-500 text-sm mt-1">{msg}</div>}
                                        </ErrorMessage>
                                    </div>
                                    <div>
                                        <label htmlFor="confirmPassword" className="block mb-2 text-sm font-medium text-gray-900">Confirm Password</label>
                                        <Field
                                            as="input"
                                            type="password"
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            autoComplete="off"
                                            placeholder="Confirm Password"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                        />
                                        <ErrorMessage name={'confirmPassword'}>
                                            {(msg) => <div className="text-red-500 text-sm mt-1">{msg}</div>}
                                        </ErrorMessage>
                                    </div>
                                    {loading ? (
                                        <div className="flex justify-center items-center">
                                            <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-gray-900"></div>
                                        </div>
                                    ) : (
                                        <button type="submit" className="w-full text-white bg-primary-500 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Submit</button>
                                    )}
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </div>
                <SweetAlert
                    success
                    show={showDialog}
                    title="Your password is reset successfully. please login now."
                    onConfirm={handleClose}
                    confirmBtnCssClass="bg-primary-500 rounded-lg text-xl text-white hover:bg-blue-600"
                />
                <SweetAlert
                    error
                    show={showErrorDialog}
                    title={'Token is expired.'}
                    onConfirm={handleCloseError}
                    confirmBtnCssClass="bg-primary-500 rounded-lg text-xl text-white hover:bg-blue-600"
                />

            </section>
        </>
    )
}

export default ResetPassword