import { createSlice } from "@reduxjs/toolkit";
import { checkIsLoggedIn, getAccessToken, getUserData } from "../helper/helper";

const initialState = {
  user: JSON.parse(getUserData()) || {},
  isLoggedIn: checkIsLoggedIn() || false,
  accessToken: getAccessToken() || "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isLoggedIn = true;
    },
    setTokens: (state, action) => {
      state.accessToken = action.payload.token;
    },
    logoutUser: (state) => {
      state.user = {};
      state.isLoggedIn = false;
      state.accessToken = "";
      state.refreshToken = "";
    },
  },
});

export const { setUser, setTokens, logoutUser } = userSlice.actions;

export default userSlice.reducer;
