import { Api } from "api/api";
import { defaultCountryCode } from "helper/constant";
import {
  getFacilityTypeList,
  getInsuranceTypeData,
  getStateListData,
  setFacilityTypeList,
  setInsuranceTypeData,
  setStateListData,
} from "helper/helper";
import React, { createContext, useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";

const FieldContext = createContext({});

const FieldProvider = ({ children }) => {
  const [facilityList, setFacilityList] = useState(getFacilityTypeList() || []);
  const [stateList, setStateList] = useState(getStateListData() || []);
  const [insuranceTypeList, setInsuranceTypeList] = useState(
    getInsuranceTypeData() || []
  );

  useEffect(() => {
    if (!facilityList.length) {
      Api.getFacilityType().then((res) => {
        if (res?.status === 200) {
          setFacilityList(
            res.data.data?.map((itm) => ({ label: itm, value: itm }))
          );
          setFacilityTypeList(
            res.data.data?.map((itm) => ({ label: itm, value: itm }))
          );
        }
      });
    }
  }, [facilityList]);

  useEffect(() => {
    if (!stateList.length) {
      Api.getStateList(defaultCountryCode).then((res) => {
        if (res?.status === 200) {
          setStateList(res.data.data);
          setStateListData(res?.data?.data);
        }
      });
    }
  }, [stateList]);

  const getInsuranceTypeList = useCallback(() => {
    Api.getInsuranceList().then((res) => {
      if (res?.status === 200) {
        setInsuranceTypeList(res.data.data);
        setInsuranceTypeData(res.data.data);
      }
    });
  }, []);

  useEffect(() => {
    getInsuranceTypeList();
  }, [getInsuranceTypeList]);
  return (
    <FieldContext.Provider
      value={{
        facilityList,
        insuranceTypeList,
        stateList,
        getInsuranceTypeList,
        setInsuranceTypeList,
        setFacilityList
      }}
    >
      {children}
    </FieldContext.Provider>
  );
};

export { FieldContext, FieldProvider };
