import React, { useState } from "react";
import { Form, Formik, ErrorMessage, Field } from "formik";
import { Api } from "../../api/api";
import { errorToast, successToast } from "../../helper/helper";
import ScrollToTop from "../../helper/utils";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const AddReferrals = () => {
    const { user } = useSelector((state) => state.user);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        whoReceivesReferrals: "",
        referralsContactNo: "",
        referralsEmail: ""
    });
    const navigate = useNavigate();

    const validate = (values) => {
        const errors = {};
        if (!values.whoReceivesReferrals) {
            errors.whoReceivesReferrals = "Who Receives Referrals is required";
        }

        if (!values.referralsContactNo) {
            errors.referralsContactNo = "Referrals Contact No is required";
        } else if (!/^[0-9]{10}$/.test(values.referralsContactNo)) {
            errors.referralsContactNo = "Invalid Referrals Contact No";
        }

        if (!values.referralsEmail) {
            errors.referralsEmail = "Referrals Email is required";
        } else if (!/\S+@\S+\.\S+/.test(values.referralsEmail)) {
            errors.referralsEmail = "Invalid email address";
        }
        return errors;
    };

    useEffect(() => {
        if ((user.who_receives_referrals != null && user.who_receives_referrals != '') && (user.referrals_email != null && user.referrals_email != '') && (user.referrals_contact_no != null && user.referrals_contact_no != '')) {
            navigate("/subscription")
        }
    }, [])

    const onSubmit = (values) => {
        setLoading(true);
        setFormData(values);
        const newFormData = new FormData();
        newFormData.append("who_receives_referrals", values.whoReceivesReferrals);
        newFormData.append("referrals_contact_no", values.referralsContactNo);
        newFormData.append("referrals_email", values.referralsEmail);
        newFormData.append('facility_name', user.facility_name);
        newFormData.append('contact_name', user.contact_name);
        newFormData.append('contact_no', user.phone);
        newFormData.append('type_of_insurances_accepted[]', user.type_of_insurances_accepted);
        newFormData.append("email", user.email);
        newFormData.append("website", user.website);
        newFormData.append("address", user.address);
        newFormData.append("city", user.city_id);
        newFormData.append("state", user.state_id);
        newFormData.append("country", user.country_id);
        newFormData.append("postal_code", user.postal_code);
        Api.updateProfile(newFormData)
            .then((res) => {
                if (res?.status === 200) {
                    successToast(res?.data?.message, 5);
                    setLoading(false);
                    setFormData({});
                    navigate("/subscription")
                }
            })
            .catch((res) => {
                errorToast(res.response?.data?.message);
                setLoading(false);
            });
    }
    ScrollToTop();
    return (
        <>
            <section>
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto mt-5 mb-5 lg:py-0">
                    <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                                Add Referrals
                            </h1>
                            <Formik
                                initialValues={formData}
                                validate={validate}
                                onSubmit={onSubmit}
                            >
                                {({ isSubmitting }) => (
                                    <Form className="space-y-4 md:space-y-6">
                                        <div>
                                            <label htmlFor="whoReceivesReferrals" className="block mb-2 text-sm font-medium text-gray-900">Who Receives Referrals</label>
                                            <Field
                                                as="input"
                                                type="text"
                                                id="whoReceivesReferrals"
                                                name="whoReceivesReferrals"
                                                autoComplete="off"
                                                placeholder="Who Receives Referrals"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                            />
                                            <ErrorMessage name={'whoReceivesReferrals'}>
                                                {(msg) => <div className="text-red-500 text-sm mt-1">{msg}</div>}
                                            </ErrorMessage>
                                        </div>
                                        <div>
                                            <label htmlFor="referralsContactNo" className="block mb-2 text-sm font-medium text-gray-900">Referrals Contact No</label>
                                            <Field
                                                as="input"
                                                type="text"
                                                id="referralsContactNo"
                                                name="referralsContactNo"
                                                autoComplete="off"
                                                placeholder="Referrals Contact No"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                            />
                                            <ErrorMessage name={'referralsContactNo'}>
                                                {(msg) => <div className="text-red-500 text-sm mt-1">{msg}</div>}
                                            </ErrorMessage>
                                        </div>
                                        <div>
                                            <label htmlFor="referrals_email" className="block mb-2 text-sm font-medium text-gray-900">Referrals Email</label>
                                            <Field
                                                as="input"
                                                type="email"
                                                id="referralsEmail"
                                                name="referralsEmail"
                                                autoComplete="off"
                                                placeholder="Referrals Email"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                            />
                                            <ErrorMessage name={'referralsEmail'}>
                                                {(msg) => <div className="text-red-500 text-sm mt-1">{msg}</div>}
                                            </ErrorMessage>
                                        </div>
                                        {loading ? (
                                            <div className="flex justify-center items-center">
                                                <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-gray-900"></div>
                                            </div>
                                        ) : (
                                            <button type="submit" className="w-full text-white bg-primary-500 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Submit</button>
                                        )}
                                    </Form>
                                )}

                            </Formik>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default AddReferrals;