import { lazy } from "react";

const UpgradePlans = lazy(() => import("@pages/UpgradePlans"));
const Dashboard = lazy(() => import("@pages/Dashboard"));
const Settings = lazy(() => import("@pages/Settings"));
const ErrorPage = lazy(() => import("@pages/PageNotFound"));
const ReferralList = lazy(() => import("@pages/ReferralList"));
const Referrals = lazy(() => import("@pages/Referrals-new"));
const SearchFacility = lazy(() => import("@pages/SearchFacility"));
const Users = lazy(() => import("@pages/Users"));
const IncomingReferral = lazy(() => import("@pages/IncomingReferral"));
const SignatureDemo = lazy(() => import("@pages/SignatureDemo"));
const Subscription = lazy(() => import("@pages/Subscription"));
const Checkout = lazy(() => import("@pages/Checkout"));
const Doctors = lazy(() => import("@pages/Doctors"));
const ChangePassword = lazy(() => import("@pages/ChangePassword"));
const ReferAFacility = lazy(() => import("@pages/ReferAFacility"));
const Chats = lazy(() => import("@pages/Chats"));

export const dashboardRoute = [
  {
    name: "Dashboard",
    path: "/portal/dashboard",
    element: <Dashboard />,
    index: true,
    advance: false,
    hasDelegate: true,
    icon: "blocks",
    requireCheck: false,
    key: 'dashboard'
  },
  {
    name: "User Profile",
    path: "/portal/profile",
    icon: "circle-user",
    element: <Settings />,
    advance: false,
    hasDelegate: false,
    key: 'user_profile'
  },
  {
    name: "Users",
    path: "/portal/users",
    element: <Users />,
    advance: true,
    hasDelegate: false,
    icon: "users",
    requireCheck: true,
    key: 'users'
  },
  {
    name: "Doctors",
    path: "/portal/doctors",
    icon: "stethoscope",
    element: <Doctors />,
    advance: true,
    hasDelegate: false,
    requireCheck: true,
    key: 'doctors'
  },
  {
    name: "Referral Management",
    path: "/portal/referral-management/details",
    element: <ReferralList />,
    icon: "circle-user",
    advance: true,
    hasDelegate: true,
    requireCheck: true,
    key: 'referral_management'
  },
  {
    name: "Referral Details",
    path: "/portal/referral-management",
    element: <Referrals />,
    advance: true,
    hasDelegate: true,
    requireCheck: true,
    key: 'referral_details'
  },
  {
    name: "Upgrade Plans",
    path: "/portal/upgrade-plan",
    element: <UpgradePlans />,
    advance: false,
    hasDelegate: false,
    requireCheck: true,
    key: 'upgrade_plans'
  },
  {
    name: "Incoming Referral",
    path: "/portal/incoming-referral",
    icon: "circle-user",
    element: <IncomingReferral />,
    advance: true,
    hasDelegate: true,
    requireCheck: true,
    key: 'incoming_referral'
  },
  {
    name: "Subscription",
    path: "subscription",
    element: <Subscription />,
    advance: false,
    hasDelegate: false,
    requireCheck: false,
    key: 'subscription'
  },
  {
    name: "Checkout",
    path: "checkout",
    element: <Checkout />,
    advance: false,
    hasDelegate: false,
    requireCheck: false,
    key: 'checkout'
  },
  {
    name: "Signature",
    path: "e-sign/:token",
    element: <SignatureDemo />,
    advance: true,
    hasDelegate: false,
    requireCheck: false,
    key: 'signature'
  },
  {
    name: "Search Facility",
    path: "/portal/search",
    icon: "search",
    element: <SearchFacility />,
    advance: true,
    hasDelegate: true,
    requireCheck: true,
    key: 'search_facility'
  },
  {
    name: "Settings",
    path: "*",
    element: <ErrorPage />,
    advance: false,
    hasDelegate: true,
    requireCheck: false,
    key: 'settings'
  },
  {
    name: "Change Password",
    path: "/portal/change-password",
    element: <ChangePassword />,
    advance: true,
    hasDelegate: true,
    requireCheck: false,
    icon: "circle-user",
    key: 'change_password'
  },
  {
    name: "Chat",
    path: "/portal/chat",
    element: <Chats />,
    advance: true,
    hasDelegate: true,
    requireCheck: false,
    icon: "circle-user",
    key: 'chat'
  },
];
