import React from "react";

const CustomButton = ({ type, children, ...rest }) => {
  return (
    <button
      type={type ? type : "button"}
      className="bg-blue-900 text-white px-8 py-3 m-2 font-bold text-base rounded-3"
      {...rest}
    >
      {children}
    </button>
  );
};

export default CustomButton;

export const ResetButton = ({ type, children, ...rest }) => {
  return (
    <button
      type={type ? type : "button"}
      className="bg-gray-500 text-white px-8 py-3 m-2 font-bold text-base rounded-3"
      {...rest}
    >
      {children}
    </button>
  );
};
