// styling
import styled from "styled-components";
import { colors, dark, light, textSizes } from "@styles/vars";
import theme from "styled-theming";
import { Field } from "formik";
// utils
import PropTypes from "prop-types";

export const Input = styled(Field)`
  height: 40px;
  padding: 10px 16px;
  border-radius: 8px;
  border: 1px solid transparent;
  font-size: ${textSizes["14"]};
  ${theme("theme", {
  light: `
    background-color: ${light.highlight};
   `,
  dark: `
    background-color: ${dark.highlight};
   `,
})};
  transition: border-color var(--transition), box-shadow var(--transition);

  &.error {
    border-color: ${colors.error};
  }

  &:hover {
    box-shadow: ${theme("theme", {
  light: `0 0 0 2px #A2C0D4`,
  dark: `0 0 0 2px ${colors.dark}`,
})};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${colors.blue};
  }
`;

const InputField = ({
  type = "text",
  placeholder,
  value,
  handler,
  id,
  name,
  className,
  onKeyPress,
}) => {
  return (
    <>
      <Input
        as="input"
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={handler}
        id={id}
        className={className || ""}
        onKeyPress={onKeyPress}
        autocomplete="off"
      />
      {/* <ErrorMessage name={name} component="div" style={{ color: "red", fontSize: "16px", padding: "5px" }} /> */}
    </>
  );
};

InputField.propTypes = {
  type: PropTypes.oneOf(["text", "password", "email", "number", "search"]),
  placeholder: PropTypes.string,
};

export default InputField;



export const CustomInput = styled.input`
  height: 40px;
  padding: 10px 16px;
  border-radius: 8px;
  border: 1px solid transparent;
  font-size: ${textSizes["14"]};
  ${theme("theme", {
  light: `
    background-color: ${light.highlight};
   `,
  dark: `
    background-color: ${dark.highlight};
   `,
})};
  transition: border-color var(--transition), box-shadow var(--transition);

  &.error {
    border-color: ${colors.error};
  }

  &:hover {
    box-shadow: ${theme("theme", {
  light: `0 0 0 2px #A2C0D4`,
  dark: `0 0 0 2px ${colors.dark}`,
})};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${colors.blue};
  }
`;
