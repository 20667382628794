import React from "react";
import { Navigate, useLocation } from "react-router";
const FileNotFound = () => {
  const location = useLocation().pathname;
  return (
    <>{!location.includes("portal") ? <Navigate to="/404"/> : 
    // <Navigate to="/portal/404"/>
    <></>
    }</>
  );
};

export default FileNotFound;
