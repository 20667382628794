export const whoWeAre = {
  title: "Who We Are",
  subtitle:
    "Our mission is simple yet profound – to streamline the referral process saving you time and money.",
  description: (
    <>
      <p>
        We are a team of dedicated individuals at Healthcare Referral Pro,
        driven by the passion to provide a system that will streamline the
        medical referral process. Managing referrals with our innovative
        platform will save you time and money.
      </p>
      <p>
        Every day, we strive to make a difference in the healthcare industry by
        providing a platform where you can manage all your referrals in one
        place. We take pride in our work and are committed to upholding the
        highest standards of professionalism and security with every member who
        joins. Join us on this exhilarating journey as we continue to redefine
        how medical referrals are done!
      </p>
    </>
  ),
};

export const howItWorks = {
  title: "How It Works",
  subtitle:
    "The process we are creating will save time by having all resources in one place.",
  description: (
    <>
      <p>
        At Healthcare Referral Pro, we have revolutionized the process of
        medical referrals to be professional and secure. Our innovative system
        seamlessly streamlines the referral process by allowing you to handle
        all your medical referrals in one convenient place. With our
        cutting-edge technology you can make your referral process quick,
        efficient, and hassle-free. Whether you need to send referrals, receive
        referrals or both, our platform is for you. Join us on this exciting
        journey towards more streamlined and effective healthcare referral
        services.
      </p>
    </>
  ),
  listData: [
    "Streamline Your Medical Referral Process",
    "Refer Patients Securely and Efficiently",
    "User-friendly Interface",
    "Data Security and Compliance with Privacy Regulations",
    "Save Time and Reduce the Risk of Errors in Communication",
    "Receive Convenience, Security, and Efficiency",
  ],
};

export const hospitals = {
  title: "Hospitals",
  descTitle:
    "Revolutionize the way your hospital operates with Healthcare Referral Pro’s cutting-edge referral system!",
  description: (
    <>
      <p>
        Say goodbye to the time-consuming and often frustrating referral
        process, and say hello to a seamless and efficient solution that will
        transform your workflow. Imagine being able to dedicate more of your
        precious time to providing top-notch care for your patients, knowing
        that the referral system is taking care of all the administrative tasks
        behind the scenes.
      </p>
      <p>
        With Healthcare Referral Pro, you can expect a significant increase in
        productivity as our streamlined system helps you manage referrals with
        ease. No longer will you have to deal with delays or confusion in
        coordinating patient transfers or specialist appointments – everything
        will be seamlessly organized at your fingertips. This means less time
        spent on paperwork and more time devoted to what truly matters –
        delivering personalized care that enhances patient outcomes.
      </p>
      <p>
        The impact of implementing Healthcare Referral Pro goes beyond just
        improving efficiency; it also reflects positively on your hospital’s
        bottom line. By maximizing operational efficiency and reducing costly
        errors associated with manual processes, you’ll see a boost in
        profitability that comes from optimized workflows. With this innovative
        solution in place, both staff members and patients alike will experience
        a higher level of satisfaction, creating an environment conducive to
        exceptional healthcare delivery.
      </p>
    </>
  ),
};

export const clinics = {
  title: "Clinics",
  descTitle: "Simplify The Referral Process For Your Clinic ",
  description: (
    <>
      <p>
        Are you tired of being bogged down by complex and time-consuming
        referral processes in your clinic? Say goodbye to administrative
        headaches with our Healthcare Referral Pro platform! This innovative
        tool is designed to revolutionize the way healthcare providers manage
        patient referrals, allowing them to dedicate more time and attention to
        delivering high-quality care. By streamlining the entire referral
        process, clinics can enhance efficiency, reduce errors, and ultimately
        improve patient outcomes. The result? A seamless experience for both
        healthcare professionals and patients alike, leading to increased
        satisfaction and loyalty.
      </p>
      <p>
        With Healthcare Referral Pro, gone are the days of lost paperwork or
        delayed appointments. Our user-friendly platform empowers clinics to
        effortlessly track and manage all their referrals in one centralized
        location. By automating tedious tasks and providing real-time updates on
        referral status, healthcare providers can ensure a smooth flow of
        communication between different medical teams. This not only enhances
        collaboration but also leads to quicker decision-making processes that
        benefit patients’ health journeys. Additionally, the increased
        efficiency translates into cost savings for clinics while boosting
        revenue streams through enhanced patient care.
      </p>
      <p>
        Join the ranks of leading healthcare providers who have already
        experienced the transformative power of Healthcare Referral Pro! Elevate
        your clinic’s reputation by offering a seamless referral process that
        prioritizes patient well-being above all else. With improved
        coordination among specialists and timely follow-ups on treatment plans,
        you can rest assured knowing that your patients are receiving
        comprehensive care every step of the way. Embrace innovation today with
        our cutting-edge platform – because when it comes to healthcare
        referrals, excellence should be effortless.
      </p>
    </>
  ),
};

export const longTermCareFacility = {
  title: "Long Term Care Facilities",
  descTitle: "Manage Your Referrals More Efficiently",
  description: (
    <>
      <p>
        Are you tired of spending countless hours dealing with administrative
        tasks instead of focusing on your patients? Say goodbye to paperwork
        overload and hello to a more efficient way of managing referrals with
        Healthcare Referral Pro! Our innovative platform simplifies the entire
        referral process, allowing you to seamlessly connect with specialists
        and coordinate care for your patients. Imagine the freedom to spend more
        quality time actually caring for those who need it most, while our
        system handles all the behind-the-scenes work.
      </p>
      <p>
        With Healthcare Referral Pro, you’ll experience a new level of
        organization and productivity that will revolutionize how you approach
        patient care. By streamlining referral processes, you can ensure that
        your patients receive timely and effective treatment from trusted
        specialists in a fraction of the time it used to take. This means
        happier patients who feel heard and supported throughout their
        healthcare journey, ultimately leading to increased satisfaction and
        loyalty towards your practice.
      </p>
      <p>
        Take control of your schedule and prioritize what truly matters –
        providing excellent care to your patients. Let Healthcare Referral Pro
        empower you to optimize workflows, collaborate seamlessly with other
        healthcare providers, and create a seamless experience for both staff
        and patients alike. Join us in transforming the future of healthcare
        delivery by embracing efficiency without sacrificing quality.
      </p>
    </>
  ),
};

export const homeHealthCareAgencies = {
  title: "Home Healthcare Agencies",
  descTitle: "Revolutionize the way you operate with Healthcare Referral Pro",
  description: (
    <>
      <p>
        The ultimate solution to supercharge your home healthcare business. Say
        goodbye to cumbersome manual processes and hello to seamless efficiency!
        With our cutting-edge platform, managing referrals has never been
        easier. Experience a streamlined workflow that will save you time, boost
        productivity, and ultimately enhance patient care.
      </p>
      <p>
        Gone are the days of sifting through mountains of paperwork and endless
        phone calls. Imagine a world where all your referral information is
        organized in one centralized hub, accessible at your fingertips anytime,
        anywhere. Healthcare Referral Pro empowers you to stay on top of tasks
        effortlessly and prioritize patient needs with precision. By harnessing
        the power of our platform, you’ll elevate your practice to new heights
        and set a higher standard of care for your clients.
      </p>
      <p>
        Take control of your operations like never before with Healthcare
        Referral Pro’s intuitive interface and customizable features tailored
        specifically for home healthcare providers. Enjoy increased transparency
        in communication between team members, reduced errors in data entry, and
        accelerated turnaround times for referrals. Embrace innovation today –
        transform how you work, streamline your processes, and witness firsthand
        the remarkable impact on both your workflow efficiency and overall
        success!
      </p>
    </>
  ),
};

export const hospiceAgencies = {
  title: "Hospice Agencies",
  descTitle:
    "Experience a revolutionary shift in your healthcare system with Healthcare Referral Pro!  ",
  description: (
    <>
      <p>
        Say goodbye to cumbersome paperwork and phone calls, and hello to
        seamless efficiency. By streamlining the referral process, you can
        dedicate more time and resources to providing top-notch care for your
        hospice patients. With our user-friendly platform, staff members can
        easily track referrals, communicate with other healthcare providers, and
        ensure a smooth transition of care. This means fewer delays, improved
        coordination among team members, and ultimately higher patient
        satisfaction rates.
      </p>
      <p>
        But wait, there’s more! Our innovative software doesn’t just stop at
        improving efficiency; it also helps boost your bottom line. By
        optimizing the referral process, you can reduce administrative costs and
        increase revenue potential. Imagine the incredible impact this could
        have on your organization’s financial health – all while maintaining a
        high standard of care for those who matter most: your patients. With
        Healthcare Referral Pro in place, you’ll not only enhance operational
        performance but also solidify your reputation as a trusted provider in
        the hospice industry.
      </p>
      <p>
        Don’t miss out on this game-changing opportunity to supercharge your
        healthcare facility with Healthcare Referral Pro! Join countless other
        organizations who have already transformed their referral processes with
        our cutting-edge technology. Embrace a future where productivity soars,
        profitability thrives, and patient outcomes flourish like never before.
        With the power of Healthcare Referral Pro at your fingertips, anything
        is possible – revolutionize how you manage referrals today!
      </p>
    </>
  ),
};

export const walkInClinics = {
  title: "Walk In Clinics",
  descTitle: "A Cutting Edge Platform To Manage Your Referrals ",
  description: (
    <>
      <p>
        Are you tired of spending countless hours managing healthcare referrals?
        Say goodbye to the hassle and hello to Healthcare Referral Pro – your
        ultimate solution for streamlining the referral process. With our
        cutting-edge platform, you can effortlessly manage referrals, freeing up
        more time to focus on providing top-quality care to your patients. Our
        secure system ensures that medical information is handled with utmost
        confidentiality, giving you peace of mind while enhancing efficiency in
        your practice.
      </p>
      <p>
        By using Healthcare Referral Pro, you’ll not only save valuable time but
        also boost profitability for your walk-in clinic. Our platform is
        designed to simplify the referral process, enabling quick and accurate
        communication between healthcare providers. With seamless integration
        and user-friendly features, making referrals has never been easier or
        more efficient. Experience a new level of productivity as you
        effortlessly navigate through our professional interface to send and
        receive referrals with ease.
      </p>
      <p>
        Join the countless healthcare providers who have revolutionized their
        practices with Healthcare Referral Pro. Enhance patient care by ensuring
        they receive timely and appropriate referrals through our secure
        platform. Increase your clinic’s reputation for excellence while
        enjoying the convenience of simplified referral management at your
        fingertips. Take the first step towards optimizing your practice today
        with Healthcare Referral Pro – where efficiency meets quality care
        seamlessly.
      </p>
    </>
  ),
};
