import { Autocomplete, TextField } from "@mui/material";
import styled from "styled-components/macro";
import theme from "styled-theming";
import { textSizes, light, dark, colors } from "@styles/vars";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState, useEffect } from "react";

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin-top: -6px;
  width: 95%;
  @media screen and(max-width:1240px) {
    margin: auto;
    width: 100%;
    padding: 10px;
  }
`;

const CustomAutocomplete = ({
  label,
  id,
  name,
  defaultValue,
  onChange,
  error,
  options: initialOptions, // Initial options
  onInputChange,
  placeholder,
  selectError,
  touched,
  loading,
  inputValue,
  ...rest
}) => {
  const [options, setOptions] = useState(initialOptions); // State to hold filtered options
  const [inputVal, setInputVal] = useState(inputValue || ""); // State to track input value

  // Filter options based on input value
  useEffect(() => {
    if (inputVal) {
      const filteredOptions = initialOptions.filter((option) =>
        option.label.toLowerCase().includes(inputVal.toLowerCase())
      );
      setOptions(filteredOptions);
    } else {
      setOptions(initialOptions);
    }
  }, [inputVal, initialOptions]);

  return (
    <InputWrapper className="mb-3 mx-3">
      <label htmlFor={name} className="block text-base font-bold text-gray-700">
        {label}
      </label>
      <CustomAutocompleteStyle
        disablePortal
        id={id}
        componentName={name}
        options={options}
        value={defaultValue}
        getOptionLabel={(option) =>
          option && option.label ? option.label : ""
        }
        onChange={(event, newValue) => {
          onChange(newValue ? newValue : null);
        }}
        inputValue={inputVal}
        onInputChange={(event, newValue) => {
          setInputVal(newValue);
          if (onInputChange) {
            onInputChange(newValue);
          }
        }}
        renderInput={(params) => (
          <TextInput
            className=""
            {...params}
            placeholder={placeholder}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading && loading === true ? (
                    <CircularProgress
                      color="inherit"
                      size={20}
                      style={{ position: "absolute", right: "35px" }}
                    />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {touched[name] && selectError && (
        <div className="text-red-500 text-sm mt-1">{selectError}</div>
      )}
    </InputWrapper>
  );
};

CustomAutocomplete.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  inputValue: PropTypes.string,
  onInputChange: PropTypes.func,
  error: PropTypes.string,
  defaultValue: PropTypes.string,
  getOptionLabel: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectError: PropTypes.string,
};

export default CustomAutocomplete;

const CustomAutocompleteStyle = styled(Autocomplete)`
  &.css-rtl-84nhb1-MuiAutocomplete-root
    .MuiOutlinedInput-root
    .MuiAutocomplete-input {
    padding: 0px;
  }
  & .MuiAutocomplete-inputRoot {
    height: auto;
    padding: 6px !important;
    border-radius: 6px;
    border: 1px solid transparent;
    font-size: ${textSizes["14"]};
    ${theme("theme", {
      light: `
      background-color: ${light.highlight};
     `,
      dark: `
      background-color: ${dark.highlight};
     `,
    })};
    transition: border-color var(--transition), box-shadow var(--transition);
  }
  &.error {
    border-color: ${colors.error};
  }

  &:hover {
    /* box-shadow: ${theme("theme", {
      light: `0 0 0 2px #A2C0D4`,
      dark: `0 0 0 2px ${colors.dark}`,
    })}; */
  }

  &:focus {
    box-shadow: 0 0 0 2px ${colors.blue};
  }
`;

export const TextInput = styled(TextField)`
  padding: 10px;
`;
