import { getReferralData, storeReferralInfo } from "helper/helper";
import React, { createContext, useState, useContext } from "react";

const referralContext = createContext({});

const ReferralProvider = ({ children }) => {
  const [referralData, setReferralData] = useState(getReferralData() || {});
  const [patient_id, setPatient_id] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [referenceInfo, setReferenceInfo] = useState({});
  const [referralStep, setReferralStep] = useState({
    patient_info: true,
    medical_insurance: false,
    medical_facilities: false,
    facility_summary: false,
    discharge_logistics: false,
    documents: false,
    referring_clinicians: false,
    sendReferral: false,
  });
  const [hasReference, setHasReference] = useState(false);

  const setReferralInfo = (data) => {
    setReferralData(data);
    storeReferralInfo(data);
  };

  const value = {
    referralData,
    referralStep,
    setReferralData: setReferralInfo,
    setReferralStep,
    activeStep,
    setActiveStep,
    isLoading,
    setIsLoading,
    patient_id,
    setPatient_id,
    referenceInfo,
    setReferenceInfo,
    hasReference,
    setHasReference,
  };

  return (
    <referralContext.Provider value={value}>
      {children}
    </referralContext.Provider>
  );
};

export const useReferralContext = () => useContext(referralContext);

export default ReferralProvider;
