// components
import React, { useState, useRef } from "react";

import Page from "@layout/Page";
import { Card, Container } from "@mui/material";
import { Formik, Form } from "formik";
import LabeledFormInput from "@ui/LabeledFormInput";
import styled from "styled-components/macro";
import { PrimaryButton } from "@ui/CustomStyle";
import CircularProgress from "@mui/material/CircularProgress";
import { colors } from "@styles/vars";
import { Api } from "api/api";
import toast from "react-hot-toast";

const ContainerLoader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding-right: 40px;
`;
const ReferAFacility = () => {
  const [loading, setLoading] = useState(false);
  const formikRef = useRef(null);

  const [formValue, setFormValue] = useState({
    contact_name: "",
    email: "",
  });
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setFormValue({
      ...formValue,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("contact_name", formValue?.contact_name);
    formData.append("email", formValue?.email);

    Api.referAFacility(formData)
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          handleResetForm();
          setFormValue({
            contact_name: '',
            email: ''
          });
          toast.success('Refer a facility successfully.');
        }
      })
      .catch((res) => {
        toast.error(res.response?.data?.message);
        setLoading(false);
      });
  }

  const handleResetForm = () => {
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!formValue.contact_name) {
      errors.contact_name = "Contact name is required";
    }

    if (!formValue.email) {
      errors.email = "Email address is required";
    }

    return errors;
  };

  return (
    <Page title="Refer A Facility">
      <Card>
        <Container>
          <Wrapper>
            <Formik
              initialValues={formValue}
              validate={validate}
              onSubmit={handleSubmit}
              innerRef={formikRef}
            >
              {({ isSubmitting, errors }) => (
                <Form>
                  <section className="bg-white p-4 rounded">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 sm:grid-cols-1 gap-4 mb-3">
                      <LabeledFormInput
                        type={"text"}
                        id={"contact_name"}
                        title="Contact Name"
                        name="contact_name"
                        value={formValue.contact_name}
                        handler={handleInputChange}
                        placeholder="Enter Contact Name"
                      />
                      <LabeledFormInput
                        type={"text"}
                        id={"email"}
                        title="Email Address"
                        name="email"
                        value={formValue.email}
                        handler={handleInputChange}
                        placeholder="Enter Email Address"
                      />
                    </div>
                    <CenterFlex>
                      {!loading ? (
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                      ) : (
                        <ContainerLoader>
                          <div>
                            <CircularProgress
                              variant="indeterminate"
                              size={40}
                              thickness={2}
                              value={100}
                              aria-busy={true}
                              sx={{
                                color: colors.blue,
                              }}
                            />
                          </div>
                        </ContainerLoader>
                      )}
                    </CenterFlex>
                  </section>
                </Form>
              )}
            </Formik>
          </Wrapper>
        </Container>
      </Card>
    </Page>
  );
};

export default ReferAFacility;

export const CenterFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

export const Wrapper = styled.div`
  padding: 70px 80px;
  @media screen {
    padding: 10px;
  }
`;
