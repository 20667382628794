import { Autocomplete, TextField } from "@mui/material";
import styled from "styled-components/macro";
import theme from "styled-theming";
import { textSizes, light, dark, colors } from "@styles/vars";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState, useEffect } from "react";
import { Api } from "api/api";
import { defaultCountryCode } from "helper/constant";

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin-top: -6px;
  width: 95%;
  @media screen and (max-width: 1240px) {
    margin: auto;
    width: 100%;
    padding: 10px;
  }
`;

const CitySelect = ({
  label,
  id,
  name,
  defaultValue,
  onChange,
  error,
  onInputChange,
  placeholder,
  selectError,
  touched,
  selectedState,
  ...rest
}) => {
  const [options, setOptions] = useState([]);
  const [cityLoading, setCityLoading] = useState(false);
  const [search, setSearch] = useState("");
  console.log(
    label,
    id,
    name,
    defaultValue,
    onChange,
    error,
    onInputChange,
    placeholder,
    selectError,
    touched,
    selectedState
  );
  const getCityListData = async (inputVal = "") => {
    setCityLoading(true);
    try {
      const res = await Api?.getCityList(
        defaultCountryCode,
        selectedState?.value,
        500,
        1,
        inputVal
      );
      if (res?.status === 200) {
        setOptions(res?.data?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching city list:", error);
    } finally {
      setCityLoading(false);
    }
  };

  // Load cities on component mount and when the state changes
  useEffect(() => {
    if (selectedState) {
      getCityListData();
    }
  }, [selectedState]);

  // Fetch cities when the search term changes and is longer than 3 characters
  useEffect(() => {
    if (search?.length > 1 && selectedState) {
      getCityListData(search);
    }
  }, [search]);

  return (
    <InputWrapper className="mb-3 mx-3">
      <label htmlFor={name} className="block text-base font-bold text-gray-700">
        {label}
      </label>
      <CitySelectStyle
        disablePortal
        id={id}
        componentName={name}
        options={options?.map((city) => ({
          value: city?.id,
          label: city?.name,
        }))}
        value={defaultValue}
        getOptionLabel={(option) =>
          option && option?.label ? option?.label : ""
        }
        onChange={(event, newValue) => {
          onChange(newValue ? newValue : null);
        }}
        inputValue={search}
        onInputChange={(event, newValue) => {
          setSearch(newValue);
        }}
        renderInput={(params) => (
          <TextInput
            className=""
            {...params}
            placeholder={placeholder}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {cityLoading && (
                    <CircularProgress
                      color="inherit"
                      size={20}
                      style={{ position: "absolute", right: "35px" }}
                    />
                  )}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {touched[name] && selectError && (
        <div className="text-red-500 text-sm mt-1">{selectError}</div>
      )}
    </InputWrapper>
  );
};

CitySelect.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  inputValue: PropTypes.string,
  onInputChange: PropTypes.func,
  error: PropTypes.string,
  defaultValue: PropTypes.string,
  getOptionLabel: PropTypes.func,
  selectError: PropTypes.string,
  selectedState: PropTypes.object,
};

export default CitySelect;

const CitySelectStyle = styled(Autocomplete)`
  &.css-rtl-84nhb1-MuiAutocomplete-root
    .MuiOutlinedInput-root
    .MuiAutocomplete-input {
    padding: 0px;
  }
  & .MuiAutocomplete-inputRoot {
    height: auto;
    padding: 6px !important;
    border-radius: 6px;
    border: 1px solid transparent;
    font-size: ${textSizes["14"]};
    ${theme("theme", {
      light: `
      background-color: ${light.highlight};
     `,
      dark: `
      background-color: ${dark.highlight};
     `,
    })};
    transition: border-color var(--transition), box-shadow var(--transition);
  }
  &.error {
    border-color: ${colors.error};
  }

  &:hover {
    /* box-shadow: ${theme("theme", {
      light: `0 0 0 2px #A2C0D4`,
      dark: `0 0 0 2px ${colors.dark}`,
    })}; */
  }

  &:focus {
    box-shadow: 0 0 0 2px ${colors.blue};
  }
`;

export const TextInput = styled(TextField)`
  padding: 10px;
`;
