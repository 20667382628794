import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Api } from "../../api/api";
import Loader from "../../component/layout/loader";
import ScrollToTop from "../../helper/utils";
import SweetAlert from 'react-bootstrap-sweetalert';

const Verify = () => {
  ScrollToTop();
  const token = useParams().token;
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const navigate = useNavigate();
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  useEffect(() => {
    verfiyEmail(token);
  }, [token]);

  const verfiyEmail = (token) => {
    setLoading(true);
    Api.verifyEmail(token)
      .then((res) => {
        setLoading(false);
        setShowDialog(true);
        // successToast(res.data.message, 4);
      })
      .catch((error) => {
        // errorToast(error.response?.data?.message);
        setShowErrorDialog(true);
        setLoading(false);
        // return navigate("/login");
      });
  };
  const handleClose = () => {
    setShowDialog(false);
    return navigate("/login");
  };
  const handleCloseError = () => {
    setShowErrorDialog(false);
    return navigate("/");
  };

  return (
    <>
      <section className="formpart p-0">
        <div className="container">
          {loading ? (
            <Loader />
          ) : (
            <div>
              <SweetAlert
                success
                show={showDialog}
                title="Your e-mail is verified. You can now login."
                onConfirm={handleClose}
                confirmBtnCssClass="bg-primary-500 rounded-lg text-xl text-white hover:bg-blue-600"
              />
              <SweetAlert
                error
                show={showErrorDialog}
                title={'Token is expired.'}
                onConfirm={handleCloseError}
                confirmBtnCssClass="bg-primary-500 rounded-lg text-xl text-white hover:bg-blue-600"
              />
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Verify;
