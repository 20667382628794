// styles
import GlobalStyles from "@styles/global";
import "react-grid-layout/css/styles.css";
import "./fonts/icomoon/style.css";
import "swiper/css";
import "swiper/css/effect-fade";

// components
import AppLayout from "./AppLayout";
import { SnackbarProvider } from "notistack";

// utils
import { ThemeProvider, StyleSheetManager } from "styled-components";
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { preventDefault } from "@utils/helpers";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// contexts
import { SidebarContextAPI } from "@contexts/sidebarContext";

// hooks
import { useEffect } from "react";
import { useInterfaceContext } from "@contexts/interfaceContext";
import { useDispatch } from "react-redux";

// actions
import { saveToLocalStorage } from "@store/features/layout";
import { useLocation } from "react-router";
import ScrollToTop from "ScrollToTop";

const App = () => {
  const location = useLocation().pathname;
  const page = document.documentElement;
  const { isDarkMode, isContrastMode, direction } = useInterfaceContext();
  const theme = createTheme({
    direction: direction,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1366,
        xl: 1536,
      },
    },
  });
  const cacheRtl = createCache({
    key: "css-rtl",
    stylisPlugins: [rtlPlugin],
  });

  useDispatch()(saveToLocalStorage());

  useEffect(() => {
    page.setAttribute("dir", direction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [direction]);

  useEffect(() => {
    isContrastMode && page.classList.add("contrast");
    preventDefault();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    // <ScrollToTop>
    <>
      {!location.includes("portal") && !location.includes("e-sign") && (
        <ScrollToTop />
      )}

      <CacheProvider value={cacheRtl}>
        {location.includes("portal") && (
          <MuiThemeProvider theme={theme}>
            <ThemeProvider theme={{ theme: isDarkMode ? "dark" : "light" }}>
              <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: direction === "ltr" ? "right" : "left",
                }}
                autoHideDuration={3000}
              >
                <SidebarContextAPI>
                  <GlobalStyles />
                  <StyleSheetManager
                    stylisPlugins={direction === "rtl" ? [rtlPlugin] : []}
                  >
                    <AppLayout />
                  </StyleSheetManager>
                </SidebarContextAPI>
              </SnackbarProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        )}
      </CacheProvider>
    </>
    // </ScrollToTop>
  );
};

export default App;
