import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { DocMsg, LinkMsg, MediaMsg, ReplyMsg, TextMsg } from "./MsgTypes";

const Message = ({ menu, handleMessage, chatHistory, userId }) => {
  const messageEndRef = useRef(null);

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  return (
    <Box
      p={3}
      sx={{
        overflowY: "auto",
        height: "calc(83vh - 165px)",
        maxHeight: "100%",
        boxSizing: "border-box",
      }}
    >
      <Stack spacing={3}>
        {chatHistory.length > 0 ? (
          chatHistory.map((el) => {
            switch (el?.message_type) {
              case "image":
                return (
                  <MediaMsg
                    key={el.id}
                    el={el}
                    menu={menu}
                    userId={userId}
                    handleMessage={handleMessage}
                  />
                );
              case "file":
                return (
                  <DocMsg
                    key={el.id}
                    el={el}
                    menu={menu}
                    handleMessage={handleMessage}
                    userId={userId}
                  />
                );
              case "link":
                return (
                  <LinkMsg
                    key={el.id}
                    el={el}
                    menu={menu}
                    handleMessage={handleMessage}
                    userId={userId}
                  />
                );
              case "reply":
                return (
                  <ReplyMsg
                    key={el.id}
                    el={el}
                    menu={menu}
                    userId={userId}
                    handleMessage={handleMessage}
                  />
                );
              default:
                return (
                  <TextMsg
                    key={el.id}
                    el={el}
                    userId={userId}
                    menu={menu}
                    handleMessage={handleMessage}
                  />
                );
            }
          })
        ) : (
          <Typography
            variant="h5"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
              padding: "80px",
              textAlign: "center",
            }}
          >
            Welcome to Healthcare Referral Pro
          </Typography>
        )}
        <div ref={messageEndRef} />
      </Stack>
    </Box>
  );
};

export default Message;
