import React, { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { MagnifyingGlass } from "phosphor-react";
import ChatElement from "@components/ChatElement";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../components/Search";

const UserList = ({
  selectedUser,
  setSelectedUser,
  setUserName,
  toggle,
  setToggle,
  socket,
  loginId,
}) => {
  const [userList, setUserList] = useState([]);
  const [filteredUserList, setFilteredUserList] = useState([]);

  useEffect(() => {
    // Ensure the socket connection is established
    if (!socket.connected) {
      socket.connect();
    }

    // Fetch the user list on component mount
    const fetchUserList = () => {
      // socket.emit("fetch_user_list", { loginId });
      socket.emit("get_user_list", {
        login_id: loginId
      });
      socket.emit("unread_user_count", {
        login_id: loginId,
      });
    };

    const handleUsers = (data) => {
      setUserList(data);
      setFilteredUserList(data);
    };

    // Listen for the user list from the server
    socket.on(`user_list_${loginId}`, handleUsers);

    // Fetch the user list initially
    fetchUserList();

    // Reconnect socket if disconnected
    socket.on("disconnect", () => {
      console.log("Socket disconnected, attempting to reconnect...");
      socket.connect();
    });

    socket.on("connect", () => {
      console.log("Socket connected");
      fetchUserList();
    });

    // Cleanup the event listeners on unmount
    return () => {
      socket.off(`user_list_${loginId}`, handleUsers);
      socket.off("disconnect");
      socket.off("connect");
    };
  }, [socket, loginId]);

  // Handle search input to filter the user list
  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredList = userList.filter((user) =>
      user.name.toLowerCase().includes(searchValue)
    );
    setFilteredUserList(filteredList);
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: { xs: "100%", md: 400 },
        display: { xs: toggle ? "block" : "none", md: "flex" },
        maxWidth: { xs: "100%", md: "400px" },
        backgroundColor: "#F8FAFF",
        boxShadow: "0px 0px 2px rgba(0,0,0,0.25)",
        minHeight: "calc(100vh - 130px)",
      }}
    >
      <Stack p={2} spacing={2} sx={{ maxHeight: "100%" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5">Facilities</Typography>
        </Stack>

        <Stack sx={{ width: "100%" }}>
          <Search>
            <SearchIconWrapper>
              <MagnifyingGlass color="#709CE6" />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search..."
              inputProps={{ "aria-label": "search" }}
              onChange={handleSearch}
            />
          </Search>
        </Stack>

        <Stack
          className="scrollbar"
          spacing={2}
          direction="column"
          sx={{ flexGrow: 1, overflowY: "auto" }}
        >
          {filteredUserList?.length > 0 ? (
            filteredUserList?.map((user) => (
              <ChatElement
                key={user.id}
                {...user}
                setSelectedUser={setSelectedUser}
                selectedUser={selectedUser}
                setUserName={setUserName}
                setToggle={setToggle}
                toggle={toggle}
              />
            ))
          ) : (
            <Typography variant="body1">No facilities found</Typography>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default UserList;
